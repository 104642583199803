<template>
  <div class="d-flex" style="min-height: 45vw;">
    <div class="content p-4 w-100">
      <div class="d-flex">
        <h4 class="card-title mt-0">24時間毎データ</h4>
        <p class="ml-5 pb-1 mb-1 align-self-end operation-office-initial-announce"
          v-show="operationOfficeInitialMessage && beforeSearchParams == null">
          最新3日間を表示しています
        </p>
        <div class="ml-auto ml-2 mb-1 align-self-end">
          <ManualLink :pageName="pageName" />
        </div>
      </div>
      <div>
        <List
          :pageInfo="pageInfo"
          :searched="searched"
          :items="dailyReportList"
          :listLoading="listLoading"
          :notReady="notReady"
          :isOperationOffice="isOperationOffice"
          :isDepartment="isDepartment"
          @select="handleSelectDeliveryPlanId"
          @changeItem="searchDailyReportList"
        />
        <pagenate
          :range="2"
          :totalPage="totalPage"
          v-model="currentPage"
        />
        <hr />
      </div>
      <div>
        <ButtonArea
          v-if="!!selectedDeliveryPlanIds.length"
          :operationOfficeId="form.operationOffice?.id"
          :deliveryPlanIds="selectedDeliveryPlanIds"
        />
      </div>
    </div>

    <SearchForm
      :loading="listLoading"
      @formchange="handleChangeSerarchForm"
      @search="searchDailyReportList"
      :isOperationOffice="isOperationOffice"
      :isSubManagementOffice="isSubManagementOffice"
      :isManagementOffice="isManagementOffice"
      :isDepartment="isDepartment"
    />
  </div>
</template>

<script>
  import { MANAGEMENT_OFFICE_TYPE, SUB_MANAGEMENT_OFFICE_TYPE, OPERATION_OFFICE_TYPE, DEPARTMENT_OFFICE_TYPE } from "@/services/consts.ts"
  import SearchForm from "@/components/DailyReportSearchForm"
  import List from "@/components/Every24hoursReportList"
  import ButtonArea from "@/components/Every24HoursReportPreviewParts/Every24hoursPreviewButtonArea"
  import Api from "@/services/api/ApiServiceFabrick";
  import PagenateItems from "@/components/PagenateItems"
  import { useAuth } from "@/services/user/Auth0UserProvider"
  import { formatDate } from "@/services/functions"
  import ManualLink from "@/components/ManualLink";

  export default {
    props: {
      showFlash: {
        type: Function
      },
      switchContentLoading: {
        type: Function
      }
    },
    data() {
      return {
        form: {},
        currentPage: 1,
        totalPage: 0,
        dailyReportList: [],
        selectedDeliveryPlanIds: [],
        listLoading: false,
        searched: false,
        beforeSearchParams: null,
        isOperationOffice: false,
        isSubManagementOffice: false,
        isManagementOffice: false,
        isDepartment: false,
        operationOfficeInitialMessage: false,
        notReady: true,
        pageName: "every24HoursReport"
      }
    },
    setup() {
      document.title = "24時間毎データ"
      const { loginWithRedirect } = useAuth();
      return { loginWithRedirect }
    },
    mounted() {
      this.switchContentLoading(true)
      Api().getSelfAccount().then((data) => {
        this.isManagementOffice = data.login_organization_type == MANAGEMENT_OFFICE_TYPE
        this.isSubManagementOffice = data.login_organization_type == SUB_MANAGEMENT_OFFICE_TYPE
        this.isOperationOffice = data.login_organization_type == OPERATION_OFFICE_TYPE
        this.isDepartment = data.login_organization_type == DEPARTMENT_OFFICE_TYPE
        this.switchContentLoading(false)
        this.notReady = false
        if (this.isOperationOffice || this.isDepartment) return this.getInitialEvery24hoursReport()
      })
    },
    watch: {
      currentPage: {
        handler() {
          this.getDailyReports(this.currentPage, false)
        }
      }
    },
    methods: {
      async getInitialEvery24hoursReport() {
        this.listLoading = true
        let selfDepartment
        let selfOperationOffice
        if (this.isDepartment) {
          selfDepartment = await this.getDepartment()
          selfOperationOffice = await this.getOperationOffice(selfDepartment[0].operationOfficeId)
        } else {
          selfOperationOffice = await this.getSelfOffice()
        }
        const request = this.makeInitialParams(selfOperationOffice, selfDepartment)
        this.apiGetDailyReports(request)
      },
      async getDepartment() {
        let department
        await Api().autoCompleteDepartments().then((data) => {
          department = data
        })
        return department
      },
      async getSelfOffice() {
        let selfOffice
        await Api().getSelfOffice().then((data) => {
          selfOffice = data
        })
        return selfOffice
      },
      makeInitialParams(operationoffice, departments = []) {
        let page = 1
        let start_date =  new Date()
        const end_date = new Date()
        start_date.setDate(start_date.getDate() - 3)
        const department = departments.length == 1 ? departments[0] : null

        const ret = {
          page: page,
          operation_office_id: operationoffice?.id,
          management_office_id: operationoffice?.management_officeable_id,
          management_officeable_type: operationoffice?.management_officeable_type,
          department_id: department?.id,
          daterange: this.convertDateTimeTodate(start_date) + " - " + this.convertDateTimeTodate(end_date),
          authority_name: 'every24hoursView'
        }
        ret["delivery_date"] = 1
        return ret
      },
      handleChangeSerarchForm(form) {
        this.form = form
      },
      handleSelectDeliveryPlanId(selectedDeliveryPlanIds) {
        this.selectedDeliveryPlanIds = selectedDeliveryPlanIds
      },
      searchDailyReportList() {
        this.operationOfficeInitialMessage = false
        this.searched = true
        this.getDailyReports()
      },
      getDailyReports(page = 1, updateOption = true) {
        this.listLoading = true
        let req
        if (updateOption || !this.beforeSearchParams) {
          req = this.makeSearchParams(page)
          this.beforeSearchParams = req
        } else {
          req = this.beforeSearchParams
          req.page = page
        }
        this.apiGetDailyReports(req)
      },
      apiGetDailyReports(req) {
        Api().getDailyReports(req).then((data) => {
          this.dailyReportList = data.items
          this.currentPage = data.current_page
          this.totalPage = data.total_page
          this.listLoading = false
          this.operationOfficeInitialMessage = true
        }).catch((error) => {
          const res = error.response
          switch (res.status) {
            case 401:
              this.loginWithRedirect({ appState: { targetUrl: location.pathname } })
              break
            case 403:
              location.reload()
              break
            case 422:
              this.showFlash("日報一覧の取得に失敗しました。", "error", false)
          }
          this.listLoading = false
        })
      },
      makeSearchParams(page){
        const ret = {
          page: page,
          operation_office_id: this.form?.operationOffice?.id,
          management_office_id: this.form?.managementOffice?.id,
          management_officeable_type: this.form?.managementOffice?.type,
          group_id: this.form?.group?.id,
          department_id: this.form?.department?.id,
          car_id: this.form?.vehicle?.id,
          driver_id: this.form?.driver?.driver_id,
          account_id: this.form?.driver?.id,
          daterange: this.convertDateTimeTodate(this.form?.start_date) + " - " + this.convertDateTimeTodate(this.form?.end_date),
          authority_name: 'every24hoursView'
        }
        const key = this.form.selectDateType == "returning" ? "return_date" : "delivery_date"
        ret[key] = 1
        return ret
      },
      convertDateTimeTodate(datetime) {
        if (!datetime) return ''
        return formatDate(datetime, '/')
      },
      async getOperationOffice(operationOfficeId) {
        let operationOffice
        await Api().getOperationOffice(operationOfficeId).then((data) => {
          operationOffice = data
        })
        return operationOffice
      },
    },
    components: {
      SearchForm,
      List,
      ButtonArea,
      pagenate: PagenateItems,
      ManualLink
    }
  }
</script>

<style>
.main-panel {
  height: initial !important;
  max-height: initial !important;
}
.exec-btn {
  background-color: rgb(21, 130, 156);
  color: white !important;
}
.reset-btn {
  background-color: rgb(245, 246, 247);
  color: rgb(21, 130, 156) !important;
  font-weight: bold;
}
.operation-office-initial-announce {
  font-size: 10px
}

</style>
