import ApiProvider from "@/services/api/providers/ApiProvider";
import conn from "@/services/ApiConnection"
import SelectRoleChecker from '@/router/selectRoleChecker';
import AutoComplete from '@/services/api/providers/AutoCompletes/RestApiProvider';

import getManagementOfficeResponse from "@/types/responses/getManagementOffice"
import getApiManagementOfficeResponse from "@/types/apiresponses/getApiManagementOffice"
import getOperationOfficeResponse from "@/types/responses/getOperationOffice"
import getPaginatedOperationOfficesRequest from "@/types/requests/getPaginatedOperationOffices"
import getPaginatedOperationOfficesResponse from "@/types/responses/getPaginatedOperationOffices"
import createOrUpdateOperationOffice from "@/types/requests/createOrUpdateOperationOffice"
import getPagenatedInstallationOfficesRequest from "@/types/requests/getPagenatedInstallationOffices"
import getPagenatedInstallationOfficesResponse from "@/types/responses/getPagenatedInstallationOffices"
import createOrUpdateInstallationOffice from "@/types/requests/createOrUpdateInstallationOffice"
import getInstallationOffice from "@/types/responses/getInstallationOffice"
import updateInstallationOfficeStatus from "@/types/requests/updateInstallationOfficeStatus"
import updateOperationOfficeStatus from "@/types/requests/updateOperationOfficeStatus"
import getOperationOfficeWithAssociationResponse from "@/types/responses/getOperationOfficeWithAssociation"
import updateOperationOfficeRequest from "@/types/requests/updateOperationOffice"
import updateReportSettingsRequest from "@/types/requests/updateReportSettings"
import getApiOperationOfficeResponse from "@/types/apiresponses/getApiOperationOffice"
import getDriversRequest from "@/types/requests/getDrivers"
import getGroupResponse from "@/types/responses/getGroup"
import getPaginatedGroupRequest from "@/types/requests/getPaginatedGroups"
import getPaginatedGroupResponse from "@/types/responses/getPaginatedGroups"
import getApiGroupResponse from "@/types/apiresponses/getApiGroup"
import getDepartmentResponse from "@/types/responses/getDepartment"
import getApiDepartmentResponse from "@/types/apiresponses/getApiDepartment"
import getVehiclesRequest from "@/types/requests/getVehicles"
import getVehicleResponse from "@/types/responses/getVehicle"
import getVehicleFormResponse from "@/types/responses/getVehicleForm"
import createVehicleRequest from "@/types/requests/createVehicle"
import searchVehicleRequest from "@/types/requests/searchVehicle"
import searchVehicleResponse from "@/types/responses/searchVehicle"
import getAccountsRequest from "@/types/requests/getAccounts"
import getAccountResponse from "@/types/responses/getAccount"
import getPaginatedAccountRequest from "@/types/requests/getPaginatedAccounts"
import getPaginatedAccountResponse from "@/types/responses/getPaginatedAccounts"
import getManagementOfficeAccountsResponse from "@/types/responses/getManagementOfficeAccounts"
import getAuthorityResponse from "@/types/responses/getAuthority"
import getApiAccountResponse from "@/types/apiresponses/getApiAccount"
import getDailyReportResponse from "@/types/responses/getDailyReport"
//import getApiDailyReportResponse from "@/types/apiresponses/getApiDailyReport"
import dailyReport from "@/types/dailyReport"
import getDailyReportRequest from "@/types/requests/getDailyReport"
import getJobStatusesRequest from "@/types/requests/getJobStatuses"
import createJobStatusRequest from "@/types/requests/createJobStatus"
import createJobStatusResponse from "@/types/apiresponses/createJobStatus"
import getDailyReportLayoutResponse from "@/types/responses/getDailyReportLayout"
import searchDailyReportLayoutRequest from "@/types/requests/searchDailyReportLayout"
import createDailyReportLayoutRequest from "@/types/requests/createDailyReportLayout"
import getApiDailyReportLayoutResponse from "@/types/apiresponses/getApiDailyReportLayout"
import getMonthlyReportResponse from "@/types/responses/getMonthlyReport"
import getMonthlyReportRequest from "@/types/requests/getMonthlyReport"
import monthlyReport from "@/types/monthlyReport"
import DeliveryPlan from '@/types/DeliveryPlan'
import DeliveryPlanWorkType from "@/types/DeliveryPlanWork"
import DeliveryPlanEvent from "@/types/DeliveryPlanEvent"
import WorkloadType from "@/types/Workload"
import SelfAccount from "@/types/SelfAccount"
import SelfOffice from "@/types/SelfOffice"
import SelfRole from "@/types/SelfRole"
// DeliveryPlanWork
import getDeliveryPlanWorkResponse from "@/types/responses/getDeliveryPlanWork"
import getDeliveryPlanDrivingStoppingPeriodResponse from "@/types/responses/getDeliveryPlanDrivingStoppingPeriod"
import getWorkloadResponse from "@/types/responses/getWorkload"
import DeliveryPlanWork from '@/services/models/DeliveryPlanWork'
import getLongTimeContinuousAlertResponse from "@/types/responses/getLongTimeContinuousAlert"
import getLongTimeContinuousAlertRequest from "@/types/requests/getLongTimeContinuousAlert"
import updateLongTimeContinuousAlertRequest from "@/types/requests/updateLongTimeContinuousAlert"
import DeliveryPlanDrivingStoppingPeriod from '@/services/models/DeliveryPlanDrivingStoppingPeriod'
import Workload from '@/services/models/Workload'
import getDrivingRecordsResponse from "@/types/responses/getDrivingRecords"
import ActualVehicleRatioTransitionsResponse from '@/types/responses/getActualVehicleRatioTransition'
import ActualVehicleRatioTransitions from '@/services/models/ActualVehicleRatioTransitions'
import SafetyDrivingScoreTransitionsResponse from '@/types/responses/getSafetyDrivingScoreTransition'
import SafetyDrivingScoreTransitions from '@/services/models/SafetyDrivingScoreTransitions'
import RestraintAndWorkingTimesResponse from '@/types/responses/getRestraintAndWorkingTime'
import RestraintAndWorkingTimes from '@/services/models/RestraintAndWorkingTimes'
import OverTimesResponse from '@/types/responses/getOverTime'
import OverTimes from '@/services/models/OverTimes'
// Spot
import getSpotResponse from "@/types/responses/getSpot"
import Spot from "@/types/spot"
import getSpotRequest from "@/types/requests/getSpot"

import getOfficesResponse from "@/types/responses/getOffices"
import createOrUpdateAccountRequest from "@/types/requests/createOrUpdateAccount"
import createOrUpdateGroupRequest from "@/types/requests/createOrUpdateGroup"
import createOrUpdateDeliveryPlanWorkingTimeRequest from "@/types/requests/createOrUpdateDeliveryPlanWorkingTime"

import AuthorityCacheApi, {authorityURL} from "@/services/models/AuthorityCacheApi"
import {getFirmwareListRequest} from "@/types/requests/getFirmwareListRequest";
import getFirmwareListResponse from "@/types/responses/getFirmwareListResponse";
import {deviceTypes} from "@/services/consts";
import getFirmwareVersionsResponse from "@/types/responses/getFirmwareVersionsResponse";
import getFirmwareRequest from "@/types/requests/getFirmwareRequest";
import getFirmwareResponse from "@/types/responses/getFirmwareResponse";
import updateFirmwareRequest from "@/types/requests/updateFirmwareRequest";
import createFirmwareRequest from "@/types/requests/createFirmwareRequest";
import deviceType from "@/types/DeviceType";
import {getDeviceFirmwareListRequest} from "@/types/requests/getDeviceFirmwareListRequest";
import getDeviceFirmwareListResponse from "@/types/responses/getDeviceFirmwareListResponse";
import DeviceFirmware from "@/types/DeviceFirmware";
import geDeviceIdsResponse from "@/types/responses/getDeviceIdsResponse";
import {attachFirmwareToDevicesRequest} from "@/types/requests/attachFirmwareToDevicesRequest";
import {detachFirmwareFromDevicesRequest} from "@/types/requests/detachFirmwareFromDevicesRequest";
import CvrConvert from "@/services/models/CvrConvert";
import createCvrUploadRequest from "@/types/requests/createCvrUpload";
import getCvrUploadResponse from "@/types/responses/getCvrUpload";
import getPagenatedCvrUploadResponse from "@/types/responses/getPagenatedCvrUpload";
import getCvrUploadHoldResponse from "@/types/responses/getCvrUploadHold";
import getCvrUploadMeasuringData from "@/types/responses/getCvrUploadMeasuringData";
import CvrUpload from "@/types/CvrUpload";
import axios from "@/services/ApiConnection";
import Firmware from "@/types/Firmware";
import getNoticesResponse from "@/types/responses/getNotices";
import getDeviceResponse from "@/types/responses/getDevice"
import createOrUpdateDevice from "@/types/requests/createOrUpdateDevice"
import getPaginatedDevicesRequest from "@/types/requests/getPaginatedDevices"
import getPaginatedDevicesResponse from "@/types/responses/getPaginatedDevices"
import updateStatus from "@/types/requests/updateStatus"
import getMakersResponse from "@/types/responses/getMakers"
import getAllowAccessOfficeResponse from "@/types/responses/getAllowAccessOffice"
import createAllowAccessOffice from "@/types/requests/createAllowAccessOffice"
import CvrUploadPeriod from "@/types/responses/getCvrUploadPeriod"
import getTemperatureInformationVehicle from "@/types/requests/getTemperatureInformationVehicle"
import temperatureInformationSpotRequest from "@/types/requests/temperatureInformationSpot"
import temperatureInformationSpotResponse from "@/types/responses/temperatureInformationSpot"
import latestSensorsResponse from "@/types/responses/getLatestSensors"
import latestSensorsRequest from "@/types/requests/getLatestSensors"
import getPagenatedSubManagementOffice from "@/types/responses/getPagenatedSubManagementOffice"
import getSubManagementOffice from "@/types/requests/getSubManagementOffice"
import createOrUpdateSubManagementOffice from "@/types/requests/createOrUpdateSubManagementOffice"
import subManagementOffice from "@/types/subManagementOffice"
import getCvrUploadSummariesResponse from "@/types/responses/getCvrUploadSummaries"
import getCvrUploadSummariesRequest from "@/types/requests/getCvrUploadSummaries"
import cvrUploadSummariesData from "@/types/responses/cvrUploadSummariesData"
import createOrUpdateUnexpectedEvent from "@/types/requests/createOrUpdateUnexpectedEvent"
import getUnexpectedEvent from "@/types/responses/getUnexpectedEvent"
import getUnexpectedEvents from "@/types/responses/getUnexpectedEvents"
import getPagenatedUnexpectedEventAndLongTimeDrivingExtensions from "@/types/responses/getPagenatedUnexpectedEventAndLongTimeContinuousDrivingExtensions"
import getUnexpectedEventAndLongTimeDrivingExtensions from "@/types/requests/getUnexpectedEventAndLongTimeContinuousDrivingExtensions"
import i18n from '@/lang/i18n';
import getRole from '@/types/responses/getRole'
import getRoles from '@/types/responses/getRoles'
import createOrUpdateRole from '@/types/requests/createOrUpdateRole'
import getPagenateDepartment from  "@/types/responses/getPagenateDepartment"
import getDepartmentAllParams from "@/types/responses/getDepartmentAllParams"
import getDepartment from "@/types/requests/getDepartment"
import createOrUpdateDepartment from "@/types/requests/createOrUpdateDepartment"
import autocompleteDepartmentRequest from "@/types/requests/autocompleteDepartment"
import getPermissionListRequestType from '@/types/requests/getPermissionList'
import getPermissionListResponseType from "@/types/responses/getPermissionList"
import getRoleTemplateResponseType from '@/types/responses/getRoleTemplate'
import getUserRolesRequestType from "@/types/requests/getUserRoles"
import getPaginateUserRolesResponseType from "@/types/responses/getPaginateUserRoles"
import getUserRoleFormResponseType from "@/types/responses/getUserRoleForm"
import upsertUserRoleFormRequestType from "@/types/requests/upsertUserRoleForm";
import getRoleAssignAccountsRequest from "@/types/requests/getRoleAssignAccounts"
import getRoleAssignAccountsResponse from "@/types/responses/getRoleAssignAccountsResponse"
import updateAllowExternalShared from "@/types/requests/updateAllowExternalShared"
import getCvrUploadsType from "@/types/requests/getCvrUploads"

class RestApiProvider implements ApiProvider {
  public autoComplete = new AutoComplete();

  public async autoCompleteManagementOffices():Promise<getManagementOfficeResponse[]> {
    const res = await conn.get('/api/v1/auto_completes/management_offices', {cache: true})
    return new Promise((resolve) => {
      const data = res?.data || []
      const ret = data.map((d: getApiManagementOfficeResponse) => {
        return {
          id_and_type: d.id + '_' + d.type,
          id: d.id,
          type: d.type,
          name: d.company_name,
        }
      })
      resolve(ret)
    })
  }
  public async autoCompleteOperationOffices():Promise<getOperationOfficeResponse[]> {
    const res = await conn.get('/api/v1/auto_completes/operation_offices', {cache: true})
    return new Promise((resolve) => {
      const data = res?.data || []
      const ret = data.map((d: getApiOperationOfficeResponse) => {
        return {
          id: d.id,
          managementOfficeId: d.management_officeable_id,
          managementOfficeType: d.management_officeable_type,
          settings_monthly_report: d.settings_monthly_report,
          tracking_service: d.tracking_service,
          name: d.operation_office_name,
          tdsensor: d.tdsensor,
        }
      })
      resolve(ret)
    })
  }
  public async getPaginatedOperationOffices(request: getPaginatedOperationOfficesRequest):Promise<getPaginatedOperationOfficesResponse> {
    let error:string|null = null;

    const res = await conn.get('/api/v1/operation_offices', { params: request }).catch(e => error = e)
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      const data = res?.data?.data || []
      const current_page = res?.data?.current_page ?? 0
      const total = res?.data?.total ?? 0
      const per = res?.data?.per_page ?? 1
      const total_page = Math.ceil(total / per)
      resolve({
        current_page: current_page,
        total_page: total_page,
        operationOffices: data
      })
    })
  }
  public async getOperationOffice(id: number, cache: true):Promise<getOperationOfficeResponse> {
    const res = await conn.get(`/api/v1/operation_offices/${id}`, {cache: cache})
    return new Promise((resolve) => {
      resolve(res?.data)
    })
  }
  public async createOperationOffice(request: createOrUpdateOperationOffice):Promise<getOperationOfficeResponse> {
    let error:string|null = null;

    const res = await conn.post('/api/v1/operation_offices', request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }
  public async updateOperationOffice(id: number, request: createOrUpdateOperationOffice):Promise<getOperationOfficeResponse> {
    let error:string|null = null;

    const res = await conn.put(`/api/v1/operation_offices/${id}`, request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }
  public async updateOperationOfficeStatus(id: number, request: updateOperationOfficeStatus):Promise<updateOperationOfficeStatus> {
    let error:string|null = null;

    const res = await conn.post(`/api/v1/operation_offices/${id}/status`, request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }
  public async updateAllowExternalShared(id: number, request: updateOperationOfficeStatus):Promise<updateAllowExternalShared> {
    let error:string|null = null;

    const res = await conn.post(`/api/v1/operation_offices/${id}/allow_external_shared`, request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }
  public async getInstallationOffice(id: number, cache: true):Promise<getInstallationOffice> {
    const res = await conn.get(`/api/v1/installation_offices/${id}`, {cache: cache})
    return new Promise((resolve) => {
      resolve(res?.data)
    })
  }
  public async getPaginatedInstallationOffices(request: getPagenatedInstallationOfficesRequest):Promise<getPagenatedInstallationOfficesResponse> {
    let error:string|null = null;

    const res = await conn.get('/api/v1/installation_offices', { params: request }).catch(e => error = e)
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      const data = res?.data?.data || []
      const current_page = res?.data?.current_page ?? 0
      const total = res?.data?.total ?? 0
      const per = res?.data?.per_page ?? 1
      const total_page = Math.ceil(total / per)
      resolve({
        current_page: current_page,
        total_page: total_page,
        installationOffices: data
      })
    })
  }
  public async createInstallationOffice(request: createOrUpdateInstallationOffice):Promise<getInstallationOffice> {
    let error:string|null = null;

    const res = await conn.post('/api/v1/installation_offices', request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }
  public async updateInstallationOffice(id: number, request: createOrUpdateInstallationOffice):Promise<getInstallationOffice> {
    let error:string|null = null;

    const res = await conn.put(`/api/v1/installation_offices/${id}`, request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }
  public async updateInstallationOfficeStatus(id: number, request: updateInstallationOfficeStatus):Promise<getPagenatedInstallationOfficesResponse> {
    let error:string|null = null;

    const res = await conn.post(`/api/v1/installation_offices/${id}/status`, request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }
  public async autoCompleteGroups():Promise<getGroupResponse[]> {
    const res = await conn.get('/api/v1/auto_completes/groups')
    return new Promise((resolve) => {
      const data = res?.data || []
      const ret = data.map((d: getApiGroupResponse) => {
        return {
          id: d.id,
          operationOfficeId: d.operation_office_id,
          managementOfficeId: d.management_officeable_id,
          managementOfficeType: d.management_officeable_type,
          name: d.group_name,
          type: d.group_type,
        }
      })
      resolve(ret)
    })
  }

  public async getPaginatedGroups(request: getPaginatedGroupRequest): Promise<getPaginatedGroupResponse> {
    let error: string | null = null;

    const res = await conn.get('/api/v1/groups', {params: request}).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      const data = res?.data?.data || [];
      const current_page = res?.data?.current_page ?? 0
      const total = res?.data?.total ?? 0
      const per = res?.data?.per_page ?? 1
      const total_page = Math.ceil(total / per)
      resolve({
        current_page: current_page,
        total_page: total_page,
        groups: data
      })
    })
  }

  public async getGroup(id: number): Promise<getGroupResponse> {
    let error: string | null = null;
    const res = await conn.get(`/api/v1/groups/${id}`).catch(e => error = e);

    return new Promise((resolve, reject) => {
      if (error) reject(error);

      const data = res?.data || [];
      resolve(data);
    })
  }

  public async createGroup(request: createOrUpdateGroupRequest): Promise<getGroupResponse> {
    let error: string | null = null;

    const res = await conn.post('/api/v1/groups', request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }

  public async updateGroup(request: createOrUpdateGroupRequest): Promise<getGroupResponse> {
    let error: string | null = null;

    const res = await conn.put('/api/v1/groups/' + request.id, request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }

  public async updateGroupStatus(request: createOrUpdateGroupRequest): Promise<getGroupResponse> {
    let error: string | null = null;

    const res = await conn.post(`/api/v1/groups/${request.id}/status`, request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }
  public async autoCompleteDepartments(request: autocompleteDepartmentRequest | undefined = undefined):Promise<getDepartmentResponse[]> {
    const res = await conn.get('/api/v1/auto_completes/departments', {params: request})
    return new Promise((resolve) => {
      const data = res?.data || []
      const ret = data.map((d: getApiDepartmentResponse) => {
        return {
          id: d.id,
          operationOfficeId: d.operation_office_id,
          name: d.name,
        }
      })
      resolve(ret)
    })
  }

  public async getVehicle(id: number): Promise<getVehicleResponse> {
    const res = await conn.get(`/api/v1/vehicles/${id}`)
    return new Promise((resolve) => {
      resolve(res?.data || [])
    })
  }

  public async getAutoCompleteVehicle(id: number): Promise<getVehicleResponse> {
    let res = {} as getVehicleResponse;
    await conn.get(`/api/v1/auto_completes/vehicles`).then((response) => {
      (response.data ?? []).forEach((r: getVehicleResponse) => {
        if (r.id == id) res = r;
      })
    })
    return new Promise((resolve) => {
      resolve(res)
    })
  }

  public async getVehicleForm(): Promise<getVehicleFormResponse> {
    const res = await conn.get(`/api/v1/vehicles_form`)
    return new Promise((resolve) => {
      resolve(res?.data || [])
    })
  }

  public async updateVehicleStatus(id: number, status: boolean): Promise<boolean> {
    return await conn.post(`/api/v1/vehicles/${id}/status`, {status: status})
  }

  public async createOrUpdateVehicle(id: null | number, request: createVehicleRequest): Promise<getVehicleResponse> {
    let url = '/api/v1/vehicles'
    if (id) url += `/${id}`
    return await conn.post(url, request, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  public async searchVehicles(param: searchVehicleRequest | getTemperatureInformationVehicle): Promise<searchVehicleResponse> {
    const res = await conn.get('/api/v1/vehicles', {
      params: param,
    })
    return new Promise((resolve) => {
      const current_page = res?.data?.current_page ?? 0
      const total = res?.data?.total ?? 0
      const per = res?.data?.per_page ?? 1
      const total_page = Math.ceil(total / per)
      resolve({
        current_page: current_page,
        total_page: total_page,
        items: res?.data?.data
      })
    })
  }

  public async getVehicleIniFile(id: number) {
    const res = await conn.get(`/api/v1/vehicles/${id}/ini_file`, {responseType: 'blob'})
    const blob = new Blob([res.data])
    let fileName = 'ini.ini'
    const disposition = res.headers['content-disposition'];
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '');
    }
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  }
  public async autoCompleteAccounts(request: undefined | getAccountsRequest):Promise<getAccountResponse[]> {
    const res = await conn.get('/api/v1/auto_completes/accounts', {params: request});
    return new Promise((resolve) => {
      const data = res?.data || []
      const ret = data.map((d: getApiAccountResponse) => {
        const base = {
          id: d.id,
          name: d.last_name + d.first_name,
          role_id: d.role_id,
          driver_id: d.driver_id,
          operationOfficeId: null,
          managementOfficeId: null,
          managementOfficeType: null,
          department_id: d.department_id,
        } as getAccountResponse

        if (d.officeable_type == "App\\Models\\OperationOffice") {
          base['operationOfficeId'] = d.officeable_id
        } else {
          base['managementOfficeId'] = d.officeable_id
          base['managementOfficeType'] = d.officeable_type
        }
        return base
      })
      resolve(ret)
    })
  }

  public async getAccount(id: number): Promise<getAccountResponse> {
    let error: string | null = null;
    const res = await conn.get(`/api/v1/accounts/${id}`).catch(e => error = e);

    return new Promise((resolve, reject) => {
      if (error) reject(error);

      const data = res?.data || [];
      resolve(data);
    })
  }

  public async getDrivers(params: getDriversRequest = {}): Promise<getAccountResponse[]> {
    let error: string | null = null;
    const res = await conn.get('/api/v1/drivers', {params: params}).catch(e => error = e);

    return new Promise((resolve, reject) => {
      if (error) reject(error);

      const data = res?.data || [];
      resolve(data);
    })
  }

  public async getPaginatedAccounts(request: getPaginatedAccountRequest): Promise<getPaginatedAccountResponse> {
    let error: string | null = null;

    const res = await conn.get('/api/v1/accounts', {params: request}).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);
      if (!request?.page) return resolve(res?.data || []);

      const data = res?.data?.data || [];
      const current_page = res?.data?.current_page ?? 0
      const total = res?.data?.total ?? 0
      const per = res?.data?.per_page ?? 1
      const total_page = Math.ceil(total / per)
      resolve({
        current_page: current_page,
        total_page: total_page,
        accounts: data
      })
    })
  }

  public async createAccount(request: createOrUpdateAccountRequest): Promise<getAccountResponse> {
    let error: string | null = null;

    const res = await conn.post('/api/v1/accounts', request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }

  public async updateAccount(request: createOrUpdateAccountRequest): Promise<getAccountResponse> {
    let error: string | null = null;

    const res = await conn.put('/api/v1/accounts/' + request.id, request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }

  public async deleteAccount(request: createOrUpdateAccountRequest): Promise<void> {
    let error: string | null = null;

    const res = await conn.delete('/api/v1/accounts/' + request.id).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve();
    })
  }

  public async updateAccountStatus(request: createOrUpdateAccountRequest): Promise<getAccountResponse> {
    let error: string | null = null;

    const res = await conn.post(`/api/v1/accounts/${request.id}/status`, request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }

  public async getDailyReports(request: getDailyReportRequest): Promise<getDailyReportResponse> {
    let error: string | null = null
    const res = await conn.get('/api/v1/daily_reports', {
      params: request
    }).catch(_error => {
      error = _error
    });
    return new Promise((resolve, reject) => {
      if (error) reject(error)
      const data = res?.data?.data || []
      const items = data.map((d: dailyReport) => {
        return {
          delivery_plan_id: d.delivery_plan_id,
          leaving_warehouse_date_time: d.leaving_warehouse_date_time,
          returning_warehouse_date_time: d.returning_warehouse_date_time,
          car_number: d.car_number,
          // driver: "", //d.driver_id,
          refueling_amount_at_warehouse: d.refueling_amount_at_warehouse,
          latest_history: d.latest_history,
          fuel_economy: d.fuel_economy,
          driver: d.driver as getAccountResponse,
          out_of_service_meter: d.out_of_service_meter,
          actual_vehicle_distance: d.actual_vehicle_distance,
          total_mileage: d.total_mileage,
          general_speed_distance: d.general_speed_distance,
          high_speed_distance: d.high_speed_distance,
          refueling_amount_at_general_gas_station: d.refueling_amount_at_general_gas_station,
          returning_warehouse_date_time_before_update: d.returning_warehouse_date_time_before_update,
          course_name: d.course_name,
          latest_stop_time: d.latest_stop_time,
          has_editted: d.has_editted,
          has_changed_driver: d.has_changed_driver,
        }
      })
      const current_page = res?.data?.current_page ?? 0
      const total = res?.data?.total ?? 0
      const per = res?.data?.per_page ?? 1
      const total_page = Math.ceil(total / per)
      resolve({
        current_page: current_page,
        total_page: total_page,
        items: items,
        total: total,
        per_page: per
      })
    })
  }

  public async getJobStatuses(request: getJobStatusesRequest): Promise<createJobStatusResponse> {
    const res = await conn.get(`/api/v2/job_statuses`, {
      params: request
    })
    return new Promise((resolve) => {
      const data = res?.data || {}
      resolve(data)
    })
  }

  public async getJobStatus(id: number): Promise<createJobStatusResponse> {
    const res = await conn.get(`/api/v2/job_statuses/${id}`)
    return new Promise((resolve) => {
      const data = res?.data || {}
      resolve(data)
    })
  }
  public async createJobStatus(request: createJobStatusRequest | FormData): Promise<createJobStatusResponse> {
    const res = await conn.post('/api/v2/job_statuses', request)
    return new Promise((resolve) => {
      const data = res?.data || {}
      resolve(data)
    })
  }

  public async getFileUrl(modelName: string, modelId: number): Promise<string> {
    let error: object | null = null
    const res = await conn.get(`/api/v1/${modelName}/${modelId}/image`, {responseType: 'blob'})
    .catch(e => error = e)

    const blob = new Blob([res.data])
    const url = URL.createObjectURL(blob);
    return new Promise((resolve, reject) => {
      if (error != null) reject(error)
      resolve(url)
    })
  }

  public async fileDownload(path: string) {
    const res = await conn.get(`/api/v1/files/download/file?file=${path}`, {responseType: 'blob'})
    .catch((e) => {
      alert('ダウンロードに失敗しました。')
    });
    if (res?.data) {
      const blob = new Blob([res.data])
      const paths = decodeURIComponent(path).split('/')
      const fileName = paths[paths.length - 1].replace(/___(.*?)\./s, '.')

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }
  }

  public async csvTemplatefileDownload(type: string) {
    const res = await conn.get(`/api/v1/csv_template_files/${type}`, {responseType: 'blob'})
    .catch((e) => {
      alert('ダウンロードに失敗しました。')
    });
    if (res?.data) {
      const blob = new Blob([res.data])
      const fileName = type + '.csv'

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }
  }
  public async getDailyReportLayouts(request: searchDailyReportLayoutRequest):Promise<getDailyReportLayoutResponse> {
    const res = await conn.get(`/api/v1/daily_report_layouts`, {
      params: request,
    });
    return new Promise((resolve) => {
      const data = res?.data?.data || {}
      const current_page = res?.data?.current_page ?? 0
      const total = res?.data?.total ?? 0
      const per = res?.data?.per_page ?? 1
      const total_page = Math.ceil(total / per)
      resolve({
        current_page: current_page,
        total_page: total_page,
        data: data
      })
      // resolve(data);
    })
  }
  public async createDailyReportLayout(request: createDailyReportLayoutRequest):Promise<getDailyReportLayoutResponse> {
    const res = await conn.post(`/api/v1/daily_report_layouts`, request);
    return new Promise((resolve) => {
      const data = res?.data || {}
      resolve(data);
    })
  }
  public async updateDailyReportLayout(request: createDailyReportLayoutRequest):Promise<getDailyReportLayoutResponse> {
    const res = await conn.put(`/api/v1/daily_report_layouts/${request.id}`, request);
    return new Promise((resolve) => {
      const data = res?.data || {}
      resolve(data);
    })
  }
  public async deleteDailyReportLayout(id: number):Promise<boolean> {
    const res = await conn.delete(`/api/v1/daily_report_layouts/${id}`)
    return new Promise((resolve) => {
      resolve(true)
    })
  }
  public async getMonthlyReports(request: getMonthlyReportRequest):Promise<getMonthlyReportResponse> {
    const res = await conn.get('/api/v1/monthly_reports', {
      params: request
    })
    return new Promise((resolve) => {
      const data = res?.data?.data || []
      const items = data.map((d: monthlyReport) => {
        return {
          driver_id: d.driver_id,
          first_name: d.first_name,
          last_name: d.last_name,
          id: d.id,
          number_plate: d.number_plate,
        }
      })
      const current_page = res?.data?.current_page ?? 0
      const total = res?.data?.total ?? 0
      const per = res?.data?.per_page ?? 1
      const total_page = Math.ceil(total / per)
      resolve({
        current_page: current_page,
        total_page: total_page,
        items: items
      })
    })
  }

  public getDeliveryPlanWorks(delivery_plan_id: string): Promise<getDeliveryPlanWorkResponse[]> {
    return DeliveryPlanWork.get(delivery_plan_id)
  }

  public updateDailyReport(deliveryPlan: DeliveryPlan, works: DeliveryPlanWorkType[]): Promise<boolean | string> {
    return DeliveryPlanWork.upsert(deliveryPlan, works)
  }

  public async getLongTimeContinuousAlerts(req: getLongTimeContinuousAlertRequest):Promise<getLongTimeContinuousAlertResponse[]> {
    const params = req;
    params.alert = 'long_time_continuous_operation';
    const res = await conn.get(`/api/v1/long_time_continuous_driving_extensions`, {
      params: params,
    });
    return new Promise((resolve) => {
      const data = res?.data || {}
      resolve(data)
    })
  }
  public async updateLongTimeContinuousAlerts(request: updateLongTimeContinuousAlertRequest[]):Promise<getLongTimeContinuousAlertResponse[]> {
    const res = await conn.post(`/api/v1/long_time_continuous_driving_extensions`, {params: request}).catch((error) => {
      return Promise.reject(error);
    });
    return new Promise((resolve) => {
      const data = res?.data || {}
      resolve(data);
    })
  }
  public async deleteLongTimeContinuousAlerts(id: number):Promise<void> {
    const res = await conn.delete(`/api/v1/long_time_continuous_driving_extensions/${id}`).catch((error) => {
      return Promise.reject(error);
    });
    return Promise.resolve();
  }

  public getDrivingStoppingPeriods(delivery_plan_id: string): Promise<getDeliveryPlanDrivingStoppingPeriodResponse[]> {
    return DeliveryPlanDrivingStoppingPeriod.get(delivery_plan_id)
  }

  public getStoppingPeriodsWithSpot(delivery_plan_id: string, operation_office_id: number): Promise<getDeliveryPlanDrivingStoppingPeriodResponse[]> {
    return DeliveryPlanDrivingStoppingPeriod.getStoppingPeriodsWithSpot(delivery_plan_id, operation_office_id)
  }

  public getWorkloads(operationOfficeId: number, work_id: string, work: string): Promise<getWorkloadResponse> {
    return Workload.get(operationOfficeId, work_id, work)
  }
  public upsertWorkloads(operationOfficeId: number, work_id: string, workItem: DeliveryPlanWorkType, items: WorkloadType[], deleteItems: number[]): Promise<getWorkloadResponse> {
    return Workload.upsert(operationOfficeId, work_id, workItem, items, deleteItems)
  }

  public async getAuthority(): Promise<getAuthorityResponse> {
    const role = SelectRoleChecker.getCurrentRole();
    const res = await conn.get(authorityURL, {
      params: {
        "organization_type": role?.organization_type,
        "organization_id": role?.organization_id
      }
    })
    return res?.data || {}
  }

  public async getDrivingRecords(delivery_plan_id: string, output_time: string): Promise<getDrivingRecordsResponse> {
    const res = await conn.get(
      '/api/v1/delivery_plans/' + delivery_plan_id + '/driving_records' + '?output_time=' + output_time
    )
    return res.data
  }

  public async getActualVehicleRatioTransitions(operationOfficeId:number): Promise<ActualVehicleRatioTransitionsResponse> {
    return ActualVehicleRatioTransitions.averageEachMonths(operationOfficeId)
  }

  public async getSafetyDrivingScoreTransitions(operationOfficeId:number): Promise<SafetyDrivingScoreTransitionsResponse> {
    return SafetyDrivingScoreTransitions.averageEachMonths(operationOfficeId)
  }

  public async getRestraintAndWorkingTimes(operationOfficeId: number|null): Promise<RestraintAndWorkingTimesResponse> {
    return RestraintAndWorkingTimes.divideByThresholds(operationOfficeId)
  }

  public async getOverTimes(operationOfficeId: number): Promise<OverTimesResponse> {
    return OverTimes.divideByThresholds(operationOfficeId)
  }

  public async getSpots(request: getSpotRequest): Promise<getSpotResponse> {
    const res = await conn.get('/api/v1/spots', {params: request})
    return new Promise((resolve) => {
      const data = res?.data || {} as Spot
      const current_page = res?.data?.current_page ?? 0
      const total = res?.data?.total ?? 0
      const per = res?.data?.per_page ?? 1
      const total_page = Math.ceil(total / per)
      resolve({
        current_page: current_page,
        total_page: total_page,
        items: res?.data?.data ?? []
      })
      resolve(data)
    })
  }

  public async updateSpot(request: Spot): Promise<Spot> {
    const res = await conn.put('/api/v1/spots/' + request.id, request)
    return new Promise((resolve) => {
      const data = res?.data || {} as Spot
      resolve(data)
    })
  }

  public async createSpot(request: Spot): Promise<Spot> {
    const res = await conn.post('/api/v1/spots', request)
    return new Promise((resolve) => {
      const data = res?.data || {} as Spot
      resolve(data)
    })
  }

  public async deleteSpot(request: Spot): Promise<Spot> {
    const res = await conn.delete('/api/v1/spots/' + request.id)
    return new Promise((resolve) => {
      const data = res?.data || {} as Spot
      resolve(<Spot>data)
    })
  }

  public async getOperationOfficeWithAssociation(): Promise<getOperationOfficeWithAssociationResponse> {
    let error: string | null = null;
    const res = await conn.get('/api/v1/request_user/operation_office')
    .catch((e) => {
      error = e;
    });

    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data)
    })
  }

  public async updateOperationOfficeWithAssociation(request: updateOperationOfficeRequest): Promise<getOperationOfficeWithAssociationResponse> {
    let error: string | null = null;

    const res = await conn.put(
      '/api/v1/request_user/operation_office',
      request
    ).catch((e) => {
      error = e;
    });

    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data)
    })
  }

  public async getManagemnetOfficeAccounts(): Promise<getManagementOfficeAccountsResponse[]> {
    let error: string | null = null;

    const res = await conn.get(
      '/api/v1/request_user/management_office_accounts',
    ).catch((e) => {
      error = e;
    });

    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data)
    })
  }

  public async updateReportSettings(id: number, request: updateReportSettingsRequest): Promise<updateReportSettingsRequest> {
    let error: string | null = null;

    const res = await conn.post(
      `/api/v1/operation_offices/${id}/report_settings`,
      request
    ).catch((e) => {
      error = e;
    });

    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data)
    })
  }

  public async getSelfAccount(): Promise<SelfAccount> {
    const res = await conn.get('/api/v1/self/account', {cache: true})
    return new Promise((resolve) => {
      const data = res?.data || {} as SelfAccount
      resolve(data)
    })
  }

  public async getSelfOffice(): Promise<SelfOffice> {
    const res = await conn.get('/api/v1/self/office', {cache: true})
    return new Promise((resolve) => {
      const data = res?.data || {} as SelfOffice
      resolve(data)
    })
  }

  public async getSelfRoles(): Promise<SelfRole[]> {
    const res = await conn.get('/api/v1/self/roles')
    return new Promise((resolve) => {
      const data = res?.data || [] as SelfRole[]
      resolve(data)
    })
  }

  public async getFirmwareList(request: getFirmwareListRequest): Promise<getFirmwareListResponse> {
    let error: string | null = null;

    const res = await conn.get(
      '/api/v1/firmware', {
        params: request
      }
    ).catch((e) => {
      error = e;
    });

    return new Promise((resolve, reject) => {
      if (error) {
        reject(error);
      }
      resolve({
        firmwares: res?.data?.firmwares.map((firmware: {
          id: string,
          version: string,
          active: boolean,
          device_type_ids: Array<number>,
          updated_at?: number,
          updated_by?: string,
        }) => {
          const responseFirmware: Firmware = {
            id: firmware.id,
            version: firmware.version,
            active: firmware.active,
            device_types: [],
            updated_by: firmware.updated_by
          }

          firmware.device_type_ids.map(typeId => {
            const foundType = deviceTypes.find(type => typeId == type.id)
            if (foundType != undefined) {
              responseFirmware.device_types.push(foundType)
            }
          })

          if (firmware.updated_at) {
            responseFirmware.updated_at = new Date((firmware.updated_at as number) * 1000)
          }

          return responseFirmware
        }),
        pagination: res?.data?.pagination,
      })
    })
  }

  public async autoCompleteFirmwaresVersions(): Promise<getFirmwareVersionsResponse> {
    let error:string|null = null;

    const res = await conn.get(
      '/api/v1/auto_completes/firmware/versions',
    ).catch((e) => {
      error = e;
    });

    return new Promise((resolve, reject) => {
      if (error) {
        reject(error);
      }

      let result = [];
      if ( res?.data ){
        result = res?.data.map((value: {
          version: string;
          id: number,
          device_type_ids: Array<number> | undefined,
          memo: string,
        } ) => {
          return {
            name: value.version,
            id: value.id,
            device_type_ids: value.device_type_ids,
            memo: value.memo,
          }
        })
      }
      resolve(result)
    })
  }

  public async getFirmware(request: getFirmwareRequest): Promise<getFirmwareResponse> {
    let error: string | null = null;

    const res = await conn.get(
      '/api/v1/firmware/view', {
        params: request
      }
    ).catch((e) => {
      error = e;
    });

    return new Promise((resolve, reject) => {
      if (error) {
        reject(error);
      }
      if (!res?.data) {
        reject('')
      }

      const apiResponse = res?.data as {
        id: string,
        version: string,
        active: boolean,
        checksum: string,
        memo: string,
        firmware: {
          path_to_file: string,
          file_name: string,
        },
        types: Array<{
          name: string,
          id: number,
        }>
      }

      const response: getFirmwareResponse = {
        firmware: {
          id: apiResponse.id,
          version: apiResponse.version,
          active: apiResponse.active,
          checksum: apiResponse.checksum,
          memo: apiResponse.memo,
          firmware: apiResponse.firmware,
          device_types: [],
        }
      }

      apiResponse.types.map(deviceType => {
        const foundType = deviceTypes.find(type => deviceType.id == type.id)
        if (foundType != undefined) {
          response.firmware.device_types.push(foundType)
        }
      })

      resolve(response)
    })
  }

  public async updateFirmware(request: updateFirmwareRequest): Promise<void> {

    let error: string | null = null;

    const res = await conn.post('/api/v1/firmware/update', request).catch((e) => {
      error = e;
    });

    return new Promise((resolve, reject) => {
      if (error) {
        reject(error);
      }
      resolve()
    })
  }

  public async createFirmware(request: createFirmwareRequest): Promise<void> {
    let error: string | null = null;

    const formData = new FormData()
    formData.append('firmware_file', request.firmwareFile)
    request.payload.ar_device_type_ids.map( deviceTypeID => formData.append('ar_device_type_ids[]', String(deviceTypeID)) )
    formData.append('version', request.payload.version)
    if (request.payload.memo) {
      formData.append('memo', request.payload.memo)
    }

    const res = await conn.post('/api/v1/firmware/create', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).catch((e) => {
      error = e;
    });

    return new Promise((resolve, reject) => {
      if (error) {
        reject(error);
      }
      resolve()
    })
  }

  public async getDeviceFirmwareList(request: getDeviceFirmwareListRequest): Promise<getDeviceFirmwareListResponse> {
    let error: string | null = null;

    const res = await conn.get(
      '/api/v1/firmware/devices', {
        params: request
      }
    ).catch((e) => {
      error = e;
    });

    return new Promise((resolve, reject) => {
      if (error || !res?.data?.pagination) {
        reject(error);
      }

      resolve({
        devices: res?.data?.devices.map((device: {
          id: string,
          device_id: string,
          model_number: string,
          type_id: number,
          operation_office_company_name?: string,
          latest_firmware?: {
            firmware_device_relation_id: number,
            id: string,
            version: string,
            type_id: number,
            activate_date_ts: number,
          },
          vehicle?: {
            number_plate: string,
          },
        }) => {
          const responseDevice: DeviceFirmware = {
            id: device.id,
            device_id: device.device_id,
            model_number: device.model_number,
            type: deviceTypes.find(type => device.type_id == type.id) as deviceType,
          }

          if (device.operation_office_company_name) {
            responseDevice.operation_office_company_name = device.operation_office_company_name
          }

          if (device.latest_firmware) {
            responseDevice.latest_firmware = {
              firmware_device_relation_id: device.latest_firmware.firmware_device_relation_id,
              id: device.latest_firmware.id,
              version: device.latest_firmware.version,
              device_type: deviceTypes.find(type => device.latest_firmware?.type_id == type.id) as deviceType,
              activate_date: new Date((device.latest_firmware.activate_date_ts as number) * 1000),
            }
          }

          if ( device.vehicle ){
            responseDevice.vehicle = device.vehicle
          }

          return responseDevice
        }),
        pagination: res?.data?.pagination,
      })
    })
  }

  public async getDeviceIds(): Promise<geDeviceIdsResponse> {
    let error: string | null = null;

    const res = await conn.get(
      '/api/v1/devices/list', {
        params: {
          field: 'device_id'
        }
      }
    ).catch((e) => {
      error = e;
    });

    return new Promise((resolve, reject) => {
      if (error) {
        reject(error);
      }
      let result = [];
      if (res?.data) {
        result = res?.data.map((value: { device_id: string; id: number }) => {
          return {
            name: value.device_id,
            id: value.id,
          }
        })
      }
      resolve(result)
    })
  }

  public async attachFirmwareToDevices(request: attachFirmwareToDevicesRequest): Promise<void> {
    let error: string | null = null;

    const res = await conn.post('/api/v1/firmware/attach', request).catch((e) => {
      error = e;
    });

    return new Promise((resolve, reject) => {
      if (error) {
        reject(error)
      }
      resolve()
    })
  }

  public async detachFirmwareFromDevices(request: detachFirmwareFromDevicesRequest): Promise<void> {
    let error: string | null = null;

    const res = await conn.post('/api/v1/firmware/detach', request).catch((e) => {
      error = e;
    });

    return new Promise((resolve, reject) => {
      if (error) {
        reject(error)
      }
      resolve()
    })
  }

  public async getOffices(): Promise<getOfficesResponse[]> {
    let error: string | null = null;

    const res = await conn.get(
      '/api/v1/offices'
    ).catch((e) => {
      error = e;
    });

    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data)
    })
  }

  public async isAPIAuthorityInitialized(): Promise<boolean> {
    const start = Date.now();

    const list = await AuthorityCacheApi.getWithParse()
    return new Promise((resolve, reject) => {
      resolve(true)
    })
  }

  public async getCvrUploads(params: getCvrUploadsType):Promise<getPagenatedCvrUploadResponse> {
    let error:string|null = null;

    const res = await conn.get(
      `/api/v1/cvr_uploads?page=${params.page}&parent_only=${params.parentOnly}&device_id=${params.deviceId}&statuses=${params.statuses}`
    ).catch((e) => {
      error = e;
    });

    return new Promise((resolve,reject) => {
      if (error) reject(error);

      const data = res?.data?.data || [];
      const current_page:number = res?.data?.current_page ?? 0
      const total = res?.data?.total ?? 0
      const per = res?.data?.per_page ?? 1
      const total_page = Math.ceil(total / per)
      resolve({
        current_page: current_page,
        total_page: total_page,
        items: data
      })
    })
  }

  public async getCvrUploadPresignedUrl(job_id: string):Promise<string|null> {
    let error:string|null = null;

    const res = await CvrConvert.getPresignedUrl(
      job_id
    ).catch((e) => {
      error = e;
    });

    return new Promise((resolve,reject) => {
      if (error) return reject(error);
      if (!res) return;

      resolve(res)
    })
  }

  public async createCvrUpload(params: createCvrUploadRequest):Promise<getCvrUploadResponse> {
    return CvrConvert.upload({
      start_datetime: params.start_datetime,
      end_datetime: params.end_datetime,
      device_id: params.device_id,
      files: params.files
    })
  }

  public async updateCvrUploadHold(id: number, hold: boolean):Promise<getCvrUploadHoldResponse> {
    let error: string | null = null;
    const res = await conn.post(`/api/v1/cvr_uploads/${id}/hold`, {
      hold: hold
    }).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);
      resolve(res.data);
    })
  }

  public async removeCvrUpload(id: number):Promise<CvrUpload> {
    let error: string | null = null;
    const res = await conn.post(`/api/v1/cvr_uploads/${id}/remove`).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);
      resolve(res.data);
    })
  }

  public async cancelCvrUpload(id: number):Promise<CvrUpload> {
    let error: string | null = null;
    const res = await conn.delete(`/api/v1/cvr_upload_request/${id}`).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) {
        reject(error);
      }
      resolve(res.data);
    })
  }

  public async downloadCvrUpload(params: getCvrUploadResponse):Promise<boolean> {
    return CvrConvert.download(params);
  }
  public async createOrUpdateDeliveryPlanWorkingTimes(workingTimes: createOrUpdateDeliveryPlanWorkingTimeRequest[],operationOfficeId:string): Promise<void> {
    let error: string | null = null;
    const res = await conn.post('/api/v1/delivery_plan_working_times', {params:{delivery_plan_working_times: workingTimes,operation_office_id:operationOfficeId }}).catch(e => error = e);

    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve();
    })
  }

  public async getCvrUploadMeasuringData(id: number):Promise<getCvrUploadMeasuringData> {
    let error:string|null = null;

    const res = await conn.get(`/api/v1/cvr_uploads/${id}/measuring_data`).catch(e => error = e);

    return new Promise((resolve,reject) => {
      if (error) return reject(error);
      if (!res) return;

      resolve(res.data)
    })
  }

  public async getNotices(): Promise<getNoticesResponse> {
    let error: string | null = null;
    const res = await conn.get('/api/v1/notices').catch(e => error = e);

    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res.data);
    })
  }

  public async upsertNoticeWatchedTime(watchedAt: string): Promise<void> {
    let error: string | null = null;
    const res = await conn.post('/api/v1/account_notice_watched_time', { watched_at: watchedAt }).catch(e => error = e);

    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve();
    })
  }
  public async getPaginatedDevices(request: getPaginatedDevicesRequest):Promise<getPaginatedDevicesResponse> {
    let error:string|null = null;

    const res = await conn.get('/api/v1/devices', { params: request }).catch(e => error = e)
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      const data = res?.data?.data || []
      const current_page = res?.data?.current_page ?? 0
      const total = res?.data?.total ?? 0
      const per = res?.data?.per_page ?? 1
      const total_page = Math.ceil(total / per)
      resolve({
        current_page: current_page,
        total_page: total_page,
        devices: data
      })
    })
  }
  public async getDevice(id: number, cache: true):Promise<getDeviceResponse> {
    const res = await conn.get(`/api/v1/devices/${id}`, {cache: cache})
    return new Promise((resolve) => {
      resolve(res?.data)
    })
  }
  public async createOrUpdateDevice(id: number | null, request: createOrUpdateDevice):Promise<getDeviceResponse> {
    let error:string|null = null;

    const res = await conn.post(`/api/v1/devices/${id}`, request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }
  public async updateDeviceStatus(id: number, request: updateStatus):Promise<getPaginatedDevicesResponse> {
    let error:string|null = null;

    const res = await conn.post(`/api/v1/devices/${id}/status`, request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }
  public async getMakers():Promise<getMakersResponse> {
    const res = await conn.get('/api/v1/makers')
    return new Promise((resolve) => {
      resolve(res?.data)
    })
  }
  public async getAllowAccessOffices():Promise<getAllowAccessOfficeResponse[]> {
    let error:string|null = null;

    const res = await conn.get('/api/v1/allow_access_offices').catch(e => error = e)
    return new Promise((resolve, reject) => {
      if (error) reject(error);
      resolve(res?.data)
    })
  }
  public async getAllowAccessOffice(id: number, cache: true):Promise<getAllowAccessOfficeResponse> {
    const res = await conn.get(`/api/v1/allow_access_offices/${id}`, {cache: cache})
    return new Promise((resolve) => {
      resolve(res?.data)
    })
  }
  public async createAllowAccessOffice(request: createAllowAccessOffice):Promise<getAllowAccessOfficeResponse> {
    let error:string|null = null;

    const res = await conn.post('/api/v1/allow_access_offices', request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }
  public async deleteAllowAccessOffice(id: number):Promise<getAllowAccessOfficeResponse> {
    let error:string|null = null;

    const res = await conn.delete(`/api/v1/allow_access_offices/${id}`).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }
  public async getCvrUploadPeriods(deviceId: number):Promise<CvrUploadPeriod[] | null> {
    let error:string|null = null;

    const res = await conn.get(`/api/v1/devices/${deviceId}/cvr_upload_periods`).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data);
    })
  }
  public async getTemperatureInformationSpot(request: temperatureInformationSpotRequest):Promise<temperatureInformationSpotResponse[]> {
    let error: string|null = null;

    const res = await conn.post('/api/v1/temperature_infomation/spots', request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }
  public async getLatestSensors(request: latestSensorsRequest):Promise<latestSensorsResponse> {
    let error: string|null = null;

    const res = await conn.get('/api/v1/latest_sensors', {params: request}).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data ?? []);
    })
  }
  public async searchSubManagementOffice(request: getSubManagementOffice):Promise<getPagenatedSubManagementOffice> {
    let error:string|null = null;

    const res = await conn.get('/api/v1/sub_management_offices', { params: request }).catch(e => error = e)
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      const data = res?.data?.data || []
      const current_page = res?.data?.current_page ?? 0
      const total = res?.data?.total ?? 0
      const per = res?.data?.per_page ?? 1
      const total_page = Math.ceil(total / per)
      resolve({
        current_page: current_page,
        total_page: total_page,
        data: data
      })
    })
  }
  public async getSubManagementOffice(id: number):Promise<subManagementOffice> {
    let error: string|null = null;

    const res = await conn.get(`/api/v1/sub_management_offices/${id}`).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data ?? []);
    })
  }
  public async updateSubManagementOfficeStatus(id: number, request: updateStatus):Promise<boolean> {
    let error:string|null = null;

    const res = await conn.post(`/api/v1/sub_management_offices/${id}/status`, request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }
  public async createOrUpdateSubManagementOffice(id: null|number, request: createOrUpdateSubManagementOffice):Promise<subManagementOffice> {
    let error:string|null = null;

    let url = '/api/v1/sub_management_offices'
    if (id) url += `/${id}`
    const res = await conn.post(url, request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }
  public async getCvrUploadSummaries(request: getCvrUploadSummariesRequest): Promise<getCvrUploadSummariesResponse | cvrUploadSummariesData[]> {
    let error:string|null = null;

    const res = await conn.post('/api/v1/cvr_upload_summaries', request).catch(e => error = e)
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      if (!res.data.data) resolve(res?.data || {});

      const data = res?.data?.data || []
      const current_page = res?.data?.current_page ?? 0
      const total = res?.data?.total ?? 0
      const per = res?.data?.per_page ?? 1
      const total_page = Math.ceil(total / per)
      resolve({
        current_page: current_page,
        total_page: total_page,
        data: data
      })
    })
  }
  public async getUnexpectedEvents(request: any): Promise<getUnexpectedEvents> {
    let error:string|null = null;

    const res = await conn.get('/api/v1/delivery_plan_unexpected_events', { params: request }).catch(e => error = e)
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      const data = res?.data?.data || []
      const current_page = res?.data?.current_page ?? 0
      const total = res?.data?.total ?? 0
      const per = res?.data?.per_page ?? 1
      const total_page = Math.ceil(total / per)
      resolve({
        current_page: current_page,
        total_page: total_page,
        data: data
      })
    })
  }
  public async getUnexpectedEvent(id: number): Promise<getUnexpectedEvent> {
    let error: string|null = null;

    const res = await conn.get(`/api/v1/delivery_plan_unexpected_events/${id}`).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data ?? []);
    })
  }
  public async createOrUpdateUnexpectedEvent(request: FormData, id: string | number | null = null): Promise<getUnexpectedEvent> {
    let error:string|null = null;

    const res = await conn.post(`/api/v1/delivery_plan_unexpected_events/${id}`, request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }
  public async getUnexpectedEventAndLongTimeDrivingExtensions(request: getUnexpectedEventAndLongTimeDrivingExtensions): Promise<getPagenatedUnexpectedEventAndLongTimeDrivingExtensions> {
    let error:string|null = null;

    const res = await conn.get('/api/v1/delivery_plan_unexpected_events_and_long_time_continuous_driving_extensions', { params: request }).catch(e => error = e)
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      const data = res?.data?.data || []
      const current_page = res?.data?.current_page ?? 0
      const total = res?.data?.total ?? 0
      const per = res?.data?.per_page ?? 1
      const total_page = Math.ceil(total / per)
      resolve({
        current_page: current_page,
        total_page: total_page,
        data: data
      })
    })
  }
  public async unexpectedEventFileDownload(filePath: string): Promise<boolean> {
    let error: object | null = null;

    const res = await conn.get(`/api/v1/unexpected_event_files/download/file?file=${filePath}`, {responseType: 'blob'}).catch(e => error = e)
    return new Promise((resolve, reject) => {
      if (error != null) reject(error);

      if (res?.data) {
        const blob = new Blob([res.data])
        const fileName = decodeURIComponent(res.headers['content-disposition']).split('=')[1]

        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
        resolve(true)
      }
    })
  }
  public async deleteUnexpectedEvent(id: number): Promise<boolean> {
    let error: object | null = null;

    await conn.delete(`/api/v1/delivery_plan_unexpected_events/${id}`).catch(e => error = e)
    return new Promise((resolve, reject) => {
      if (error != null) reject(error);
      resolve(true)
    })
  }

  public async getRoles(): Promise<getRoles> {
    let error: object | null = null;

    const res = await conn.get(`/api/v1/role_assignments`).catch(e => error = e)
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res)
    })
  }

  public async getRole(id: number): Promise<getRole> {
    let error: object | null = null;

    const res = await conn.get(`/api/v1/role_assignments/${id}`).catch(e => error = e)
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res)
    })
  }

  public async createOrUpdateRole(id: number, request: createOrUpdateRole): Promise<getRole> {
    let error: object | null = null;

    const res = await conn.post(`/api/v1/role_assignments/${id}`, request).catch(e => error = e)
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }

  public async getRoleAssignAccounts(request: getRoleAssignAccountsRequest): Promise<getRoleAssignAccountsResponse> {
    let error: object | null = null;

    const res = await conn.get(`/api/v1/role_assignments_accounts`, { params: request }).catch(e => error = e)
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res)
    })
  }

  public async getDepartments(request?: getDepartment): Promise<getPagenateDepartment> {
    let error:string|null = null;

    const res = await conn.get('/api/v1/departments', { params: request }).catch(e => error = e)
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      if (!request?.page) return resolve(res);

      const data = res?.data?.data || []
      const current_page = res?.data?.current_page ?? 0
      const total = res?.data?.total ?? 0
      const per = res?.data?.per_page ?? 1
      const total_page = Math.ceil(total / per)
      resolve({
        current_page: current_page,
        total_page: total_page,
        data: data
      })
    })
  }
  public async getDepartment(id: number): Promise<getDepartmentAllParams> {
    let error: string|null = null;

    const res = await conn.get(`/api/v1/departments/${id}`).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data ?? []);
    })
  }
  public async createOrUpdateDepartment(id: number | null, request: createOrUpdateDepartment): Promise<getDepartmentAllParams> {
    let error:string|null = null;

    let url = '/api/v1/departments'
    if (id) url += `/${id}`
    const res = await conn.post(url, request).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res?.data || {});
    })
  }
  public async deleteDepartment(id: number): Promise<boolean> {
    let error: string|null = null;

    const res = await conn.delete(`/api/v1/departments/${id}`).catch(e => error = e);
    return new Promise((resolve, reject) => {
      if (error) reject(error);

      resolve(res);
    })
  }

  public async getPermissionList(params: getPermissionListRequestType): Promise<getPermissionListResponseType>{
    let error:string|null = null;

    const res = await conn.get(`/api/v1/permissions/`, { params: params }).catch(e => error = e);

    return new Promise((resolve,reject) => {
      if (error) return reject(error);
      resolve(res.data)
    })
  }

  public async getRoleTemplates(): Promise<getRoleTemplateResponseType[]>{
    let error:string|null = null;

    const res = await conn.get(`/api/v1/user_role_templates`).catch(e => error = e);

    return new Promise((resolve,reject) => {
      if (error) return reject(error);
      resolve(res.data)
    })
  }

  public async getUserRoles(searchParams: getUserRolesRequestType): Promise<getPaginateUserRolesResponseType> {
    let error:string|null = null;

    const res = await conn.get(`/api/v1/user_roles/`, { params: searchParams }).catch(e => error = e);

    return new Promise((resolve,reject) => {
      if (error) return reject(error);
      resolve(res.data)
    })
  }
  public async getUserRoleForm(id: number): Promise<getUserRoleFormResponseType> {
    let error:string|null = null;

    const res = await conn.get(`/api/v1/user_roles/form/${id}`).catch(e => error = e);

    return new Promise((resolve,reject) => {
      if (error) return reject(error);
      resolve(res.data)
    })
  }

  public async createUserRoleForm(param: upsertUserRoleFormRequestType): Promise<getUserRoleFormResponseType> {
    let error:string|null = null;

    const res = await conn.post(`/api/v1/user_roles/form`, param).catch(e => error = e);

    return new Promise((resolve,reject) => {
      if (error) return reject(error);
      resolve(res.data)
    })
  }

  public async updateUserRoleForm(id: number, param: upsertUserRoleFormRequestType): Promise<getUserRoleFormResponseType> {
    let error:string|null = null;

    const res = await conn.put(`/api/v1/user_roles/form/${id}`, param).catch(e => error = e);

    return new Promise((resolve,reject) => {
      if (error) return reject(error);
      resolve(res.data)
    })
  }

  public async deleteUserRoleForm(id: number): Promise<{status: boolean}> {
    let error:string|null = null;

    const res = await conn.delete(`/api/v1/user_roles/form/${id}`).catch(e => error = e);

    return new Promise((resolve,reject) => {
      if (error) return reject(error);
      resolve(res.data)
    })
  }
}

export default RestApiProvider
