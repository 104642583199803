export const verticalLinePlugin = {
  id: 'verticalLinePlugin',
  beforeDraw: (chart) => {
    const ctx = chart.ctx;
    const xAxis = chart.scales.x;
    const yAxis = chart.scales.y;
    if (!xAxis?.ticks || xAxis.ticks.length <= 1) {
      return;
    }
    const stepSize = xAxis.ticks[1].value - xAxis.ticks[0].value;

    ctx.save();
    ctx.strokeStyle = 'rgba(0, 0, 0, 0.1)';
    ctx.lineWidth = 1;

    const xAxisMin = xAxis?.min ?? 0;
    const xAxisMax = xAxis?.max ?? 0;
    if (!xAxisMin && !xAxisMax) {
      return;
    }
    for (let i = xAxisMin; i <= xAxisMax; i += stepSize) {
      const x = xAxis.getPixelForValue(i);
      ctx.beginPath();
      ctx.moveTo(x, yAxis.top);
      ctx.lineTo(x, yAxis.bottom);
      ctx.stroke();
    }

    ctx.restore();
  }
};
