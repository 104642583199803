import $ from 'jquery'
import moment from 'moment';
import Chart from 'chart.js/auto';
import { Interaction } from 'chart.js';
import { getRelativePosition } from 'chart.js/helpers';
import 'chartjs-adapter-moment';
import 'hammerjs';
import zoomPlugin from 'chartjs-plugin-zoom';
import { formatDateTimeWithSeconds } from './functions.ts';
import { verticalLinePlugin } from "@/services/Every24hoursChartPlugins/verticalLinePlugin.js"

Chart.register(zoomPlugin);

const defultOutputTime = '24h';
const xAxisDivisions = 4;
const xLabelMinuteInterval = { '24h': 360, '8h': 120, '12min': 3, '4min': 1 };
const xAxisStepSize = { '24h': 60, '8h': 30, '12min': 0.5, '4min': 0.25 };
const distanceScalesY = {
  '24h': { 'min': 0, 'max': 10, 'ticksStepSize': 5 },
  '8h': { 'min': 0, 'max': 10, 'ticksStepSize': 5 },
  '12min': { 'min': 0, 'max': 0.4, 'ticksStepSize': 0.2 },
  '4min': { 'min': 0, 'max': 0.4, 'ticksStepSize': 0.2 }
};
const chartNames = ['speed', 'distance', 'rotation-speed'];

export const initCharts = function(index, getDrivingRecordsFunc, loading, loginWithRedirect) {
  Chart.register(verticalLinePlugin);
  let deliveryPlanId = $($('.delivery-plan-id')[index]).val();
  let outputTime = defultOutputTime;
  if ($(".output-time-" + index + ":checked").val()) {
    outputTime = $($(`input[name='output-time-${index}']:checked`)[0]).val();
  }
  const speedValueDateTimes = $("#speed-chart-" + index).data('speed-value-date-times');
  const distanceValueDateTimes = $("#distance-chart-" + index).data('distance-value-date-times');
  const rotationSpeedValueDateTimes = $("#rotation-speed-chart-" + index).data('rotation-speed-value-date-times');
  const drivingTimes = $("#driving-time-at-output-time-" + index).data('driving-times');
  const leavingWarehouseDateTime = $("#start-time-" + index).data('start-time');
  const returningWarehouseDateTime = $("#end-time-" + index).data('end-time');

  let minMaxScaleLabel = createMinMaxScaleLabel(leavingWarehouseDateTime, outputTime);
  let deliveryPlansManager = manageDeliveryPlans(deliveryPlanId, index, speedValueDateTimes, distanceValueDateTimes, rotationSpeedValueDateTimes, drivingTimes, minMaxScaleLabel, leavingWarehouseDateTime, returningWarehouseDateTime);
  let speedChart = initSpeedChart(deliveryPlansManager, outputTime);
  let distanceChart = initDistanceChart(deliveryPlansManager, outputTime);
  let rotationSpeedChart = initRotationSpeedChart(deliveryPlansManager, outputTime);
  handleOnClickOutputTime(speedChart, distanceChart, rotationSpeedChart, deliveryPlansManager, getDrivingRecordsFunc, loading, loginWithRedirect);

  const leavingUnixTime = new Date(leavingWarehouseDateTime).getTime();
  const returningUnixTime = new Date(returningWarehouseDateTime).getTime();
  chartNames.forEach((chartName) => {
    const slider = $('#' + chartName + '-chart-slider-' + index)[0];
    slider.value = leavingUnixTime;
    slider.min = leavingUnixTime;
    slider.max = returningUnixTime;

    slider.step = 1000 * 4 * 60; // 4分毎
    const args = {
      handleEvent: trackingSliderValue,
      chartName: chartName,
      index: index,
      deliveryPlansManager: deliveryPlansManager,
      charts: [speedChart, distanceChart, rotationSpeedChart],
      convertDateTimeFormat: formatDateTimeWithSeconds
    }
    slider.addEventListener('input', args);
  })

  setOutputTimeRegionText(deliveryPlansManager, outputTime);
};

export const createParameter = () => {
  let params = [];
  for (var i = 0; i < $(".every-24hours-report-container").length; i++) {
    let query = {
      delivery_plan_id: $($('.delivery-plan-id')[i]).val(),
      output_time: $(`input[name='output-time-${i}']`).filter(":checked").val(),
      from_date_time: $($('.from-date-time')[i]).val(),
      to_date_time: $($('.to-date-time')[i]).val(),
      date_at_output_time: $("#date-at-output-time-" + i).text(),
      start_time_at_output_time: $("#start-time-at-output-time-" + i).text(),
      distance_at_output_time: $("#distance-at-output-time-" + i).text(),
      driving_time_at_output_time: $("#driving-time-at-output-time-" + i).text(),
      maximum_speed_at_output_time: $("#maximum-speed-at-output-time-" + i).text(),
      x_label: $($('.x-label-str')[i]).val(),
      speed_data: JSON.parse($($('.speed-data')[i]).val()),
      distance_data: JSON.parse($($('.distance-data')[i]).val()),
      rotation_speed_data: JSON.parse($($('.rotation-speed-data')[i]).val()),
    };
    params.push(query);
  }
  return params
}

function trackingSliderValue() {
  const targetName = this.chartName + '-chart-slider-' + this.index;
  const slider = $('#' + targetName)[0];
  const sliderValue = Number(slider.value);
  const sliderValueDate = new Date(sliderValue);
  const sliderValueDateTimeStr = this.convertDateTimeFormat(sliderValueDate);

  const outputTime = $(`input[name='output-time-${this.deliveryPlansManager.id()}']:checked`).val();
  const stepSize = xLabelMinuteInterval[outputTime];
  const xAxisLastUnixTime = sliderValue + (stepSize * xAxisDivisions * 60 * 1000);
  const xAxisLastDateTimeStr = this.convertDateTimeFormat(new Date(xAxisLastUnixTime));
  this.deliveryPlansManager.updateScaleMaxMin(xAxisLastDateTimeStr, sliderValueDateTimeStr);

  updateSpeedChart(this.charts[0], this.deliveryPlansManager, outputTime);
  updateDistanceChart(this.charts[1], this.deliveryPlansManager, outputTime);
  updateRotationSpeedChart(this.charts[2], this.deliveryPlansManager, outputTime);
  setOutputTimeRegionText(this.deliveryPlansManager, outputTime);

  const otherCharts = chartNames.filter((chartName) => {
    return chartName != this.chartName
  })
  otherCharts.forEach((chartName) => {
    const otherSlider = $('#' + chartName + '-chart-slider-' + this.index)[0];
    otherSlider.value = sliderValue;
  })
}

function manageDeliveryPlans(dpid, index, speeds, distances, rotationSpeeds, drivingDateTimes, scaleMinMax, leavingWarehouseDateTime, returningWarehouseDateTime) {
  return (function() {
    const deliveryPlanId = dpid;
    const id = index;
    let speedDataIndex = 1;
    let distanceDataIndex = 1;
    let rotationSpeedDataIndex = 1;
    let speedData = speeds;
    let distanceData = distances;
    let rotationSpeedData = rotationSpeeds;
    let drivingTimes = drivingDateTimes;
    let leavingDateTime = leavingWarehouseDateTime;
    let returningDateTime = returningWarehouseDateTime;
    let scaleMin = scaleMinMax[0];
    let scaleMax = scaleMinMax[1];
    const dataSetsLimit = 3;

    function getDataToSetChart(deliveryPlanData, dataIndex) {
      if (!Object.keys(deliveryPlanData).length) return {};
      let range;
      if (dataIndex == 1) {
        range = [dataIndex, dataIndex + 1, dataIndex + 2];
      } else if (dataIndex == Object.keys(deliveryPlanData).length) {
        range = [dataIndex - 2, dataIndex - 1, dataIndex];
      } else if (1 < dataIndex && dataIndex < Object.keys(deliveryPlanData).length) {
        range = [dataIndex - 1, dataIndex, dataIndex + 1];
      }
      return { ...deliveryPlanData[range[0]], ...deliveryPlanData[range[1]], ...deliveryPlanData[range[2]] };
    }

    function getDataFrom(drivingTimes, startDate, endDate) {
      let drivingTimesBetweenStartEnd = [];
      for (let index in drivingTimes) {
        if (endDate < drivingTimes[index][0]['start']) break;
        if (startDate > drivingTimes[index][drivingTimes[index].length - 1]['end']) continue;
        drivingTimesBetweenStartEnd.push(drivingTimes[index]);
      }
      return drivingTimesBetweenStartEnd.flat();
    }

    function updateDataIndex(deliveryPlanData, currentIndex, updateIndexFunc) {
      let index = 1;
      if (Object.keys(deliveryPlanData).length <= dataSetsLimit) {
        updateIndexFunc(index);
        return currentIndex == index;
      }

      const rangeStartDate = new Date(scaleMin);
      for (let i = 1; i <= Object.keys(deliveryPlanData).length; i++) {
        const currentIndexDates = Object.keys(deliveryPlanData[i]);
        const currentIndexFirstDate = new Date(currentIndexDates[0]);
        const currentIndexLastDate = new Date(currentIndexDates[currentIndexDates.length - 1]);
        if (currentIndexFirstDate <= rangeStartDate && rangeStartDate <= currentIndexLastDate) {
          index = i;
          break;
        }
      }
      updateIndexFunc(index);
      return true;
    }

    function getSpeedDataIndex() {
      return speedDataIndex;
    }

    function setSpeedDataIndex(index) {
      speedDataIndex = parseInt(index);
    }

    function getDistanceDataIndex() {
      return distanceDataIndex;
    }

    function setDistanceDataIndex(index) {
      distanceDataIndex = parseInt(index);
    }

    function getRotationSpeedDataIndex() {
      return rotationSpeedDataIndex;
    }

    function setRotationSpeedDataIndex(index) {
      rotationSpeedDataIndex = parseInt(index);
    }

    return {
      id: function() { return id },
      deliveryPlanId: function() { return deliveryPlanId },
      speedDataIndex: function() { return getSpeedDataIndex() },
      updateSpeedDataIndex: function() { return updateDataIndex(speedData, getSpeedDataIndex, setSpeedDataIndex) },
      distanceDataIndex: function() { return getDistanceDataIndex() },
      updateDistanceDataIndex: function() { return updateDataIndex(distanceData, getDistanceDataIndex, setDistanceDataIndex) },
      rotationSpeedDataIndex: function() { return getRotationSpeedDataIndex() },
      updateRotationSpeedDataIndex: function() { return updateDataIndex(rotationSpeedData, getRotationSpeedDataIndex, setRotationSpeedDataIndex) },
      speedData: function() { return speedData },
      updateSpeedData: function(speeds) { speedData = speeds },
      distanceData: function() { return distanceData },
      updateDistanceData: function(distances) { distanceData = distances },
      rotationSpeedData: function() { return rotationSpeedData },
      updateRotationSpeedData: function(rotationSpeeds) { rotationSpeedData = rotationSpeeds },
      drivingTimes: function() { return drivingTimes },
      updateDrivingTimes: function(drivingDateTimes) { drivingTimes = drivingDateTimes },
      speedDataToSetChart: function() { return getDataToSetChart(speedData, speedDataIndex) },
      distanceDataToSetChart: function() { return getDataToSetChart(distanceData, distanceDataIndex) },
      rotationSpeedDataToSetChart: function() { return getDataToSetChart(rotationSpeedData, rotationSpeedDataIndex) },
      getDrivingTimesFrom: function(startDate, endDate) { return getDataFrom(drivingTimes, startDate, endDate) },
      scaleMin: function() { return scaleMin },
      scaleMax: function() { return scaleMax },
      updateScaleMaxMin: function(max, min) {
        scaleMax = max;
        scaleMin = min;
      },
      leavingWarehouseDateTime: function() { return leavingDateTime },
      returningWarehouseDateTime: function() { return returningDateTime }
    }
  })();
}

const MARKER_SIZE = 3;
const MARKER_PADDING = 1;
function createMarkerArray(deliveryPlansManager, chartData) {
  const speedData = getSpeedDataFilterByRange(deliveryPlansManager);
  const tragetTimes = Object.keys(speedData);
  const speedArray = Object.values(speedData);
  const maxSpeed = Math.max(...speedArray);

  let isSet = false;
  return Object.keys(chartData).map((key) => {
    const value = chartData[key];
    // 時間外のものを除外
    if (!tragetTimes.includes(key)) {
      return null;
    }
    // 登録済みもしくは最高値以外を除外
    if (value != maxSpeed || isSet) {
      return null;
    }
    isSet = true;
    return (Number(value) + MARKER_SIZE + MARKER_PADDING);
  })
}

function getSpeedDataFilterByRange(deliveryPlansManager) {
  const fromDate = new Date(deliveryPlansManager.scaleMin());
  const toDate = new Date(deliveryPlansManager.scaleMax());
  const speedValueDateTimes = deliveryPlansManager.speedDataToSetChart();

  const speedDataFilterByRange = [];
  for(var dateTime in speedValueDateTimes) {
    const targetDate = new Date(dateTime);
    if (targetDate > toDate) {
      break;
    }
    if (targetDate < fromDate) {
      continue;
    }
    speedDataFilterByRange[dateTime] = speedValueDateTimes[dateTime];
  }
  return speedDataFilterByRange;
}

let corsairPoint = {x: 0, y: 0};
const setCorsair = (point = {x: -1, y: -1}) => {
  corsairPoint = point;
}

const plugin = {
  id: 'corsair',
  defaults: {
      width: 1,
      color: '#FF4949',
      dash: [3, 3],
  },
  afterInit: (chart, args, opts) => {
    chart.corsair = {
      x: 0,
      y: 0,
    }
  },
  afterTooltipDraw: (chart, args, options) => {
    const {inChartArea} = args;
    const {caretX,caretY} = args.tooltip;

    setCorsair({x: caretX, y: caretY});
  },
  afterEvent: (chart, args) => {
    const {inChartArea} = args;

    chart.corsair = {
      x: corsairPoint.x,
      y: corsairPoint.y,
      draw: inChartArea,
    };
    chart.draw();
  },
  beforeDatasetsDraw: (chart, args, opts) => {
    const {ctx} = chart
    const {top, bottom, left, right} = chart.chartArea
    const {x, y, draw} = chart.corsair
    if (!draw) {
      return;
    }

    ctx.save()

    ctx.beginPath();
    ctx.lineWidth = opts.width;
    ctx.strokeStyle = opts.color;
    ctx.setLineDash(opts.dash);
    ctx.moveTo(x, bottom);
    ctx.lineTo(x, top);
    ctx.moveTo(left, y);
    ctx.lineTo(right, y);
    ctx.stroke();

    ctx.restore();
  }
}

Interaction.modes.showTooltip = function(chart, e, options, useFinalPosition) {
  const position = getRelativePosition(e, chart);

  const items = [];
  Interaction.evaluateInteractionItems(chart, 'x', position, (element, datasetIndex, index) => {
    if (element.inXRange(position.x, useFinalPosition) && datasetIndex == 0) {
      items.push({element, datasetIndex, index});
    }
  });
  if (!items.length) {
    setCorsair()
  }
  return items;
};

function initSpeedChart(deliveryPlansManager, outputTime) {
  let ctxChart = $("#speed-chart-" + deliveryPlansManager.id())[0].getContext("2d");
  let stepSize = xLabelMinuteInterval[outputTime];
  let dataToShowChart = deliveryPlansManager.speedDataToSetChart();

  let config = {
    type: "line",
    data: {
      labels: Object.keys(dataToShowChart),
      datasets: [{
        label: '',
        fill: false,
        borderColor: 'black',
        borderWidth: 1,
        pointRadius: 0,
        data: Object.values(dataToShowChart)
      },
      {
        label: '',
        fill: false,
        rotation: 180,
        pointStyle: 'triangle',
        borderColor: 'orange',
        borderWidth: MARKER_SIZE,
        data: createMarkerArray(deliveryPlansManager, dataToShowChart)
      }]
    },
    options: {
      animation: false,
      interaction: {
        mode: 'showTooltip',
      },
      scales: {
        y: {
          min: 0,
          max: 140
        },
        x: {
          type: 'time',
          time: {
            parser: 'yyyy-MM-DD HH:mm:ss',
            unit: 'minute',
            stepSize: stepSize,
            tooltipFormat: 'yyyy/MM/DD HH:mm:ss',
            displayFormats: {
                'minute': 'HH:mm'
            }
          },
          display: false,
          min: deliveryPlansManager.scaleMin(),
          max: deliveryPlansManager.scaleMax(),
          ticks: {
            stepSize: xAxisStepSize[outputTime]
          }
        }
      },
      plugins: {
        corsair: {
          color: 'black'
        },
        tooltip: {
          callbacks: {
            label: () => {
              return ''
            },
            beforeBody: (values) => {
              const value = values[0];
              const speed = Math.ceil(value.raw * 100) / 100;
              return `${speed} km`;
            },
          }
        },
        legend: {
            display: false
        },
        zoom: {
          pan: {
            enabled: false,
            mode: "x",
            speed: 20,
          },
          zoom: {
            wheel: { enabled: false },
            drag: { enabled: false },
            false: { enabled: false },
            mode: ""
          }
        },
      },
      maintainAspectRatio: false
    },
    plugins: [plugin],
  };
  let chart = new Chart(ctxChart, config);
  if (!Object.values(dataToShowChart).length) stackTextOnChart($("#speed-chart-" + deliveryPlansManager.id()));
  return chart;
}

function initDistanceChart(deliveryPlansManager, outputTime) {
  let ctxChart = $("#distance-chart-" + deliveryPlansManager.id())[0].getContext("2d");
  let stepSize = xLabelMinuteInterval[outputTime];
  let scalesY = distanceScalesY[outputTime];
  let dataToShowChart = deliveryPlansManager.distanceDataToSetChart();
  let config = {
    type: "line",
    data: {
      labels: Object.keys(dataToShowChart),
      datasets: [{
          label: '',
          fill: false,
          borderColor: 'black',
          borderWidth: 1,
          pointRadius: 0,
          data: Object.values(dataToShowChart)
      }]
    },
    options: {
      animation: false,
      interaction: {
        mode: 'showTooltip',
      },
      scales: {
        y: {
          min: scalesY['min'],
          max: scalesY['max'],
          ticks: {
            stepSize: scalesY['ticksStepSize']
          }
        },
        x: {
          type: 'time',
          time: {
            parser: 'yyyy-MM-DD HH:mm:ss',
            unit: 'minute',
            stepSize: stepSize,
            tooltipFormat: 'yyyy/MM/DD HH:mm:ss',
            displayFormats: {
                'minute': 'HH:mm'
            }
          },
          display: false,
          min: deliveryPlansManager.scaleMin(),
          max: deliveryPlansManager.scaleMax(),
          ticks: {
            stepSize: xAxisStepSize[outputTime]
          }
        }
      },
      plugins: {
        corsair: {
          color: 'black'
        },
        tooltip: {
          callbacks: {
            label: () => {
              return ''
            },
            beforeBody: (values) => {
              const value = values[0];
              const distance = Math.ceil(value.raw * 100) / 100;
              return `${distance} km`;
            },
          }
        },
        legend: {
          display: false
        },
        zoom: {
          pan: {
            enabled: false,
            mode: "x",
            speed: 20,
          },
          zoom: {
            wheel: { enabled: false },
            drag: { enabled: false },
            false: { enabled: false },
            mode: ""
          }
        },
      },
      maintainAspectRatio: false
    },
    plugins: [plugin],
  };
  let chart = new Chart(ctxChart, config);
  if (!Object.values(dataToShowChart).length) stackTextOnChart($("#distance-chart-" + deliveryPlansManager.id()));
  return chart;
}

function initRotationSpeedChart(deliveryPlansManager, outputTime) {
  let ctxChart = $("#rotation-speed-chart-" + deliveryPlansManager.id())[0].getContext("2d");
  let stepSize = xLabelMinuteInterval[outputTime];
  let dataToShowChart = deliveryPlansManager.rotationSpeedDataToSetChart();
  let config = {
    type: "line",
    data: {
      labels: Object.keys(dataToShowChart),
      datasets: [{
        label: '',
        fill: false,
        borderColor: 'black',
        borderWidth: 1,
        pointRadius: 0,
        data: Object.values(dataToShowChart)
      }]
    },
    options: {
      animation: false,
      interaction: {
        mode: 'showTooltip',
      },
      scales: {
        y: {
          min: 0,
          max: 4000,
          ticks: {
            stepSize: 2000
          }
        },
        x: {
          type: 'time',
          time: {
            parser: 'yyyy-MM-DD HH:mm:ss',
            unit: 'minute',
            stepSize: stepSize,
            tooltipFormat: 'yyyy/MM/DD HH:mm:ss',
            displayFormats: {
                'minute': 'HH:mm'
            }
          },
          display: false,
          min: deliveryPlansManager.scaleMin(),
          max: deliveryPlansManager.scaleMax(),
          ticks: {
            stepSize: xAxisStepSize[outputTime]
          }
        }
      },
      plugins: {
        corsair: {
          color: 'black'
        },
        tooltip: {
          callbacks: {
            label: () => {
              return ''
            },
            beforeBody: (values) => {
              const value = values[0];
              const speed = Math.ceil(value.raw * 100) / 100;
              return `${speed} rpm`;
            },
          }
        },
        legend: {
          display: false
        },
        zoom: {
          pan: {
            enabled: false,
            mode: "x",
            speed: 20,
          },
          zoom: {
            wheel: { enabled: false },
            drag: { enabled: false },
            false: { enabled: false },
            mode: ""
          }
        },
      },
      maintainAspectRatio: false
    },
    plugins: [plugin],
  };
  let chart = new Chart(ctxChart, config);
  if (!Object.values(dataToShowChart).length) stackTextOnChart($("#rotation-speed-chart-" + deliveryPlansManager.id()));
  return chart;
}

function stackTextOnChart(chartDom) {
  let style = "transform: translate(-50%, -50%); -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); font-size: 40px; position: absolute; left: 50%; top: 50%;"
  let noDataText = $('<div>', { class:'text-secondary', text: 'データがありません。', style: style });
  chartDom.after(noDataText);
}

function handleOnClickOutputTime(speedChart, distanceChart, rotationSpeedChart, deliveryPlansManager, getDrivingRecordsFunc, loading, loginWithRedirect) {
  $(`input[name='output-time-${deliveryPlansManager.id()}']`).on('click', function() {
    loading.status = true
    let outputTime = $(this)[0].value;
    let minMaxScaleLabel = createMinMaxScaleLabel(deliveryPlansManager.scaleMin(), outputTime);
    getDrivingRecordsFunc(deliveryPlansManager.deliveryPlanId(), outputTime).then((result) => {
      deliveryPlansManager.updateScaleMaxMin(minMaxScaleLabel[1], minMaxScaleLabel[0]);
      deliveryPlansManager.updateSpeedData(result.speed_data);
      deliveryPlansManager.updateDistanceData(result.distance_data);
      deliveryPlansManager.updateRotationSpeedData(result.rotation_speed_data);
      deliveryPlansManager.updateDrivingTimes(result.driving_times);
      deliveryPlansManager.updateSpeedDataIndex();
      deliveryPlansManager.updateDistanceDataIndex();
      deliveryPlansManager.updateRotationSpeedDataIndex();
      let stepSize = xLabelMinuteInterval[outputTime];
      updateSpeedChart(speedChart, deliveryPlansManager, outputTime, stepSize);
      updateDistanceChart(distanceChart, deliveryPlansManager, outputTime, stepSize, distanceScalesY[outputTime]);
      updateRotationSpeedChart(rotationSpeedChart, deliveryPlansManager, outputTime, stepSize);
      setOutputTimeRegionText(deliveryPlansManager, outputTime);
      loading.status = false
    }).catch((error) => {
      const res = error.response
      switch (res.status) {
        case 401:
          loginWithRedirect({ appState: { targetUrl: location.pathname } })
          break
        case 403:
          location.reload()
          break
        case 422:
      }
      loading.status = false
    })
  });
}

function createMinMaxScaleLabel(fromDateTime, outputTime) {
  let minuteInterval = xLabelMinuteInterval[outputTime];
  let tempDate = new Date(fromDateTime);
  tempDate.setMinutes(tempDate.getMinutes() + (minuteInterval * xAxisDivisions));

  return [fromDateTime, formatDateTimeWithSeconds(tempDate)];
}

function updateSpeedChart(speedChart, deliveryPlansManager, outputTime, stepSize = null) {
  speedChart.options.scales.x.min = deliveryPlansManager.scaleMin();
  speedChart.options.scales.x.max = deliveryPlansManager.scaleMax();
  speedChart.options.scales.x.ticks.stepSize = xAxisStepSize[outputTime]
  let speedDataToSetChart = deliveryPlansManager.speedDataToSetChart();

  if (stepSize) {
    speedChart.options.scales.x.time.stepSize = stepSize;
  }
  if (stepSize || deliveryPlansManager.updateSpeedDataIndex()) {
    speedChart.data.labels = Object.keys(speedDataToSetChart);
    speedChart.data.datasets[0].data = Object.values(speedDataToSetChart);
  }
  speedChart.data.datasets[1].data = createMarkerArray(deliveryPlansManager, speedDataToSetChart);
  speedChart.update();
}

function updateDistanceChart(distanceChart, deliveryPlansManager, outputTime, stepSize = null, scalesY = null) {
  distanceChart.options.scales.x.min = deliveryPlansManager.scaleMin();
  distanceChart.options.scales.x.max = deliveryPlansManager.scaleMax();
  distanceChart.options.scales.x.ticks.stepSize = xAxisStepSize[outputTime]
  if (stepSize) distanceChart.options.scales.x.time.stepSize = stepSize;

  if (scalesY) {
    distanceChart.options.scales.y.min = scalesY['min'];
    distanceChart.options.scales.y.max = scalesY['max'];
    distanceChart.options.scales.y.ticks.stepSize = scalesY['ticksStepSize'];
  }

  if (stepSize || deliveryPlansManager.updateDistanceDataIndex()) {
    let distanceDataToSetChart = deliveryPlansManager.distanceDataToSetChart();
    distanceChart.data.labels = Object.keys(distanceDataToSetChart);
    distanceChart.data.datasets[0].data = Object.values(distanceDataToSetChart);
  }
  distanceChart.update();
}

function updateRotationSpeedChart(rotationSpeedChart, deliveryPlansManager, outputTime, stepSize = null) {
  rotationSpeedChart.options.scales.x.min = deliveryPlansManager.scaleMin();
  rotationSpeedChart.options.scales.x.max = deliveryPlansManager.scaleMax();
  rotationSpeedChart.options.scales.x.ticks.stepSize = xAxisStepSize[outputTime]
  if (stepSize) rotationSpeedChart.options.scales.x.time.stepSize = stepSize;
  if (stepSize || deliveryPlansManager.updateRotationSpeedDataIndex()) {
    let rotationSpeedDataToSetChart = deliveryPlansManager.rotationSpeedDataToSetChart();
    rotationSpeedChart.data.labels = Object.keys(rotationSpeedDataToSetChart);
    rotationSpeedChart.data.datasets[0].data = Object.values(rotationSpeedDataToSetChart);
  }
  rotationSpeedChart.update();
}

function setOutputTimeRegionText(deliveryPlansManager, outputTime) {
  changeOutputTimeText(deliveryPlansManager.id());
  let fromDate = deliveryPlansManager.scaleMin();
  let toDate = deliveryPlansManager.scaleMax();
  setXLabel(fromDate, deliveryPlansManager.id(), outputTime);
  setValueOfHidden(deliveryPlansManager, fromDate, toDate);
  setDateAtOutputTime(deliveryPlansManager.id(), fromDate);
  setStartTimeAtOutputTime(deliveryPlansManager.id(),fromDate);
  setDrivingTime(deliveryPlansManager, fromDate, toDate);
  setDistanceAtOutputTime(deliveryPlansManager, fromDate, toDate);
}

function setXLabel(min, index, outputTime) {
  let dateTime = moment(min).format("yyyy/MM/DD HH:mm:ss");
  let minuteInterval = xLabelMinuteInterval[outputTime];
  let addMinute = minuteInterval;
  let xLabel = [];
  const xLabelCount = 5;

  for(var i = 0; i < xLabelCount; i++) {
    xLabel.push(dateTime);
    $($(".x-label-" + index + " .x-label")[i]).text(dateTime); // 速度チャートのX軸
    $($(".x-label-" + index + " .x-label")[i + xLabelCount]).text(dateTime); // 距離チャートのX軸
    $($(".x-label-" + index + " .x-label")[i + (xLabelCount * 2)]).text(dateTime); // 回転数チャートのX軸
    const format = i == xLabelCount - 2 ? "yyyy/MM/DD HH:mm:ss" : "HH:mm:ss";
    dateTime = moment(min).add(addMinute, "m").format(format);
    addMinute += minuteInterval;
  }
  $($('.x-label-str')[index]).val(xLabel.join(','));
}

function setValueOfHidden(deliveryPlansManager, fromDate, toDate) {
  let index = deliveryPlansManager.id();
  $($('.from-date-time')[index]).val(fromDate);
  $($('.to-date-time')[index]).val(toDate);
  $($('.speed-data')[index]).val(JSON.stringify(deliveryPlansManager.speedDataToSetChart()));
  $($('.distance-data')[index]).val(JSON.stringify(deliveryPlansManager.distanceDataToSetChart()));
  $($('.rotation-speed-data')[index]).val(JSON.stringify(deliveryPlansManager.rotationSpeedDataToSetChart()));
}

function setDateAtOutputTime(index, date) {
  $("#date-at-output-time-" + index).text(moment(date).format('yyyy-MM-DD'));
}

function setStartTimeAtOutputTime(index, date) {
  $("#start-time-at-output-time-" + index).text(moment(date).format('HH:mm:ss'));
}

function setDrivingTime(deliveryPlansManager, fromDate, toDate) {
  let leavingWarehouseDateTime = deliveryPlansManager.leavingWarehouseDateTime();
  let returningWarehouseDateTime = deliveryPlansManager.returningWarehouseDateTime();
  let startDate = Math.max(new Date(fromDate).getTime(), new Date(leavingWarehouseDateTime).getTime());
  let endDate = Math.min(new Date(returningWarehouseDateTime).getTime(), new Date(toDate).getTime());
  let drivingTimes = deliveryPlansManager.getDrivingTimesFrom(startDate, endDate);
  let totalDrivingTime = 0;
  if (drivingTimes.length) {
    for (let i = 0; i < drivingTimes.length; i++) {
      let $drivingStartTime = drivingTimes[i]['start'];
      let $drivingEndTime = drivingTimes[i]['end'];
      if ($drivingStartTime >= endDate) break;
      if ($drivingEndTime <= startDate) continue;
      if ($drivingStartTime < startDate) $drivingStartTime = startDate;
      if ($drivingEndTime > endDate) $drivingEndTime = endDate;
      totalDrivingTime += ($drivingEndTime - $drivingStartTime);
    }
  }
  $("#driving-time-at-output-time-" + deliveryPlansManager.id()).text((new Date(totalDrivingTime)).toUTCString().match(/..:..:../)[0]);
}

function setDistanceAtOutputTime(deliveryPlansManager, fromDate, toDate) {
  // 浮動小数対応。小数５桁のdistanceを整数にするmultiple
  const multiple = 100000;

  let distance = 0;
  let startDate = new Date(fromDate);
  let endDate = new Date(toDate);
  let distanceData = deliveryPlansManager.distanceDataToSetChart();
  for(var dateTime in distanceData) {
    let targetDate = new Date(dateTime);
    if (targetDate > endDate) break;
    if (targetDate < startDate) continue;

    distance += distanceData[dateTime] * multiple;
  }

  let displayDistance = distance / multiple + "km";
  if (distance === 0) {
    displayDistance = "N/A";
  }
  $("#distance-at-output-time-" + deliveryPlansManager.id()).text(displayDistance);
}

function changeOutputTimeText(index) {
  let checkedElement = $(`input[name='output-time-${index}']:checked`)[0];
  $(".every-output-time-" + index).text(checkedElement)
}
