import OverTime from '@/types/OverTime';
import CvrUpload from '@/lang/ja/cvr_upload';
import Dashboard from '@/lang/ja/dashboard';
import FirmwareList from '@/lang/ja/firmwareList';
import DeviceFirmware from '@/lang/ja/deviceFirmware';
import Every24HoursReport from '@/lang/ja/evry_24_hours_report';
import MonthlyReportWorkingTimesSetting from '@/lang/ja/monthlyReportWorkingTimesSetting';

export default {
  common: {
    loadingText: "Loading...",
    unknownErrorMessage: "不明なエラー",
    notFound: "404 Not Found",
    cancelBtn: "キャンセル",
    confirmBtn: "確認",
    saveBtn: "保存",
    deleteBtn: "削除",
    selectBtn: "選択",
    backBtn: "戻る",
    confirmationModal: {
      title: '確認',
      confirmDelete: "削除してもよろしいですか？",
    },
    closeBtn: "閉じる",
    downloadBtn: "ダウンロード",
    downloadingBtn: "ダウンロード中...",
    previewBtn: "プレビュー",
    invalidURL: "不正なURLです。",
  },
  sideMenue: {
    dashboard: "ダッシュボード",
    firmware: {
      firmware: "ファームウェア",
      firmwareList: "ファームウェア一覧",
      FirmwareUpdate: "アップデート設定"
    },
    dailyReport: {
      dailyReport: "日報",
      dailyReportOutput: "日報出力",
      dailyReportLayout: "日報レイアウト",
      UnexpectedEventAndAlertStatuseList: "特例適用",
    },
    every24HoursReport: "24時間毎データ",
    monthryReport: "月報",
    TemperatureSensor: {
      title: "温度管理",
      TemperatureSensor: "温度管理グラフ",
      TemperaturInformationNumerical: "温度情報数値確認",
    },
    safeDrivingReport: "安全運転帳票",
    settings: {
      title: "マスタ設定",
      account: "アカウント",
      vehicle: "車両管理",
      device: "デバイス管理",
      subManagementOffice: "準管理会社",
      operationOffice: "運営会社",
      spot: "地点登録",
      drivingScore: "運転スコア",
      group: "グループ",
      installationOffice: "設置会社",
      allowAccessOffice: "運営会社参照許可",
      roleAssaignment: "権限付与",
      department: "組織",
      userRoles: "権限管理",
    },
    downloadData: {
      downloadData: "データ出力",
      downloadDataList: "一括出力",
      downloadDataHistory: "一括出力履歴",
    },
    cvr: "CVR 動画アップロード",
    trackingService: "動態管理サービス",
    support: "サポート",
  },
  pages: {
    dashboard: Dashboard,
    firmwareList: FirmwareList,
    deviceFirmware: DeviceFirmware,
    monthlyReportWorkingTimesSetting: MonthlyReportWorkingTimesSetting,
    cvr_uploads: CvrUpload,
    every24HoursReport: Every24HoursReport,
    mainTemplate: {
      selectRole: "組織選択",
      logoutBtn: "サインアウト",
      reloadDialog: {
        title: "再ロード",
        message: "ログイン情報が変更されています。[再ロード]を押して、画面を再ロードしてください",
        closeButton: "再ロード",
      },
    },
    common: {
      list: {
        reading: "読み込み中",
        noData: "表にデータがありません。",
        runFilter: "フィルターを実行してください。",
        initialMessage: "最新3日間を表示しています",
      },
      button: {
        exec: "実行",
        loading: "実行中...",
        reset: "リセット",
        close: "閉じる",
        newCreate: "新規作成",
        conservation: "保存",
        return: "戻る",
        allSelect: "全て選択",
        clear: "クリア",
        displayReload: "画面更新",
        csvExport: "CSV出力",
        csvImport: "CSV入力",
        csvUpsert: "CSV一括更新",
        csvUpsertExport: "CSV一括更新出力",
        csvUpsertImport: "CSV一括更新入力",
        search: "検索",
      },
      messages: {
        changeStatusSuccess: "ステータスの保存に成功しました。",
        changeStatusFail: "ステータスの保存に失敗しました。",
        forbidden: "権限がありません。",
        notEditAuthority: "編集権限がありません。",
        success: "保存に成功しました。",
        failed: "保存に失敗しました。",
        successUpdate: "更新に成功しました。",
        failedUpdate: "更新に失敗しました。",
        successDelete: "削除に成功しました。",
        failedDelete: "削除に失敗しました。",
        removeSuccess: "削除に成功しました。",
        csvUpsertModalMessage: "ファイルを選択してください。「CSV一括更新出力」でダウンロードしたファイルを編集して、こちらからアップロードします。",
        failedRemove: "削除に失敗しました。",
      },
      sortType: {
        asc: "ASC",
        desc: "DESC",
      },
      filter: {
        title: "フィルター",
        status: {
          title: "ステータス",
          all: "全て",
          active: "有効のみ",
        },
        startTime: "開始日時",
        endTime: "終了日時",
        errorMssages: {
          pleaseSelect: "選択してください",
          pleaseSelectCorrectRange: "範囲を正しく指定してください。"
        },
        organization: "所属組織",
      },
      datePicker: {
        select: "選択",
        cancel: "キャンセル",
        time: "時",
        minute: "分",
      },
      autocomplete: {
        department: "組織",
      },
      weeks: {
        Sun: "日",
        Mon: "月",
        Tue: "火",
        Wed: "水",
        Thu: "木",
        Fri: "金",
        Sat: "土",
      }
    },
    accounts: {
      filter: {
        accountCategory: "アカウント区分",
        loginId: "ログインID",
        accountName: "アカウント名",
        department: "組織",
      },
      form: {
        send_cvr_upload_mail: "CVRアップロード完了通知",
      },
      csv: {
        importTitle: "アカウントの入力",
        csvUpsertModalMessage: "ファイルを選択してください。「CSV一括更新出力」でダウンロードしたファイルを編集して、こちらからアップロードします。",
        itTenkoTitle: "IT点呼連携データ",
        itTenkoImport: "IT点呼連携データ入力",
        itTenkoExport: "IT点呼連携データ出力",
      },
      messages: {
        badRequestForDelete: '削除できませんでした。権限が付与されています。'
      }
    },
    daily_reports: {
      updateModal: {
        applicationOfSpecialCaseButton: "特例適用",
        registerUnexpectedEvents: "予期し得ない事象への対応時間の登録",
        editUnexpectedEvents: "予期し得ない事象への対応時間の編集",
        registerSpecialExtensionContinuousOperation: "連続運転時間特別延長の登録",
        messages: {
          restTimeLessThanThreshold: "休息は3時間以上の時間が必要です。3時間未満の場合は休憩を入力してください。"
        },
      },
      ignore_longtime_continuous_warning: {
        confirmModalTitle: "連続運転特別延長の登録",
        confirmModalBody: "サービスエリア又はパーキングエリア等が満車である等により駐車又は停車できず、やむを得ず連続運転時間が４時間を超える場合は４時間３０分まで運転可能です。",
        updateModalTitle: "連続運転時間超過",
        ignoreBtn: "特別延長",
        ignoredBtn: "特別延長済",
        cantChangeBtn: "設定不可",
        emptyAlerts: "連続運転時間超過はありません。",
      },
      unexpectedEventConfirmModal: {
        title: "予期し得ない事象への対応時間として認められるには基準を満たす必要があります。",
        messages: {
          no1: "1  次のいずれかの事象により生じた運行の遅延に対応するための時間であること。",
          no1of1: "① 運転中に乗務している車両が予期せず故障したこと。",
          no1of2: "② 運転中に予期せず乗船予定のフェリーが欠航したこと。",
          no1of3: "③ 運転中に災害や事故の発生に伴い、道路が封鎖されたこと又は道路が渋滞したこと。",
          no1of4: "④ 異常気象（警報発表時）に遭遇し、運転中に正常な運行が困難となったこと。",
          no2: "2  予期し得ない事象の発生を特定できる客観的な資料を提出できること。",
          example: "（一例）",
          no2of1: "① 修理会社等が発行する故障車両の修理明細書等",
          no2of2: "② フェリー運航会社等のホームページに掲載されたフェリー欠航情報の写し",
          no2of3: "③ 公益財団法人日本道路交通情報センター等のホームページに掲載された \n    道路交通情報の写し（渋滞の日時・原因を特定できるもの）",
          no2of4: "④ 気象庁のホームページ等に掲載された異常気象等に関する気象情報等の写し",
        }
      },
      unexpectedEventRegisterModal: {
        titlePrefix: "予期し得ない事象への対応時間の",
        registerTitle: "登録",
        editTitle: "編集",
        startTime: "開始日時",
        endTime: "終了日時",
        totalTime: "所要時間",
        address: "対応を行った場所",
        note: "特例適用事由",
        documents: "予期し得ない事象の発生を特定できる客観的な資料",
      },
    },
    unexpected_event_and_alert_statuses: {
      title: "特例適用一覧",
      listHeader: {
        tripId: "TripID",
        division: "区分",
        startTime: "開始時間",
        endTime: "終了時間",
        numberPlate: "車番",
        driverName: "ドライバー",
        edit: "詳細・編集",
        updatedByAndDate: "最終更新者/日付",
      },
      unexpectedEvents: "予期し得ない事象",
      longTimeContinuousDriving: "連続運転時間超過の延長",
      filter: {
        selectDivision: {
          title: "区分",
          all: "全て",
          unexpectedEvent: "予期し得ない事象",
          longTimeContinuousDriving: "連続運転時間超過",
        },
        radioButton: {
          startTime: "開始時間",
          endTime: "終了時間",
        }
      },
      deleteModal: {
        longTimeContinuousDrivingExtension: {
          note: "選択した連続運転時間超過の延長を削除します。",
        },
        unexpectedEvent: {
          note: "選択した予期し得ない事象の登録を削除します。",
        },
      },
      fileDownload: {
        notExistsFilePath: "不正なファイルパスです。"
      }
    },
    operation_offices: {
      form: {
        reportDeadLineDay: {
          title: "月報の締日",
          endOfTheMonth: "月末",
          oneDayBefore: "月末の1日前",
          twoDayBefore: "月末の2日前",
        },
        reckonedDate: "起算日",
        message: {
          isNotReckonedDate: "起算日を設定してください。"
        },
        ittenko: {
          acquisitionTime: "取得時間",
          beforeLeaving: "出庫前",
          afterReturning: "帰庫後",
        },
        hours: "時間",
        minutes: "分",
      },
      list: {
        allowExternalShared: "外部共有許可の保存に成功しました。",
      }
    },
    temperature_sensors: {
      title: "温度情報数値確認",
      list: {
        header: {
          department: "組織",
          vehicle: "車番",
          temperature: "温度",
          dateTime: "取得時間",
          sopt: "取得場所",
          ch1: "CH1",
          ch2: "CH2",
          ch3: "CH3",
          ch4: "CH4",
        }
      },
      buttonName: {
        pdfGraphButton: "グラフ出力",
        pdfListButton: "数値データ出力"
      }
    },
    sub_management_office: {
      modelName: "sub_management_offices",
      title: {
        title: "準管理会社",
        listTitle: "準管理会社一覧",
        createTitle: "作成",
        editTitle: "編集",
      },
      filter: {
        companyName: "社名"
      },
      list: {
        header: {
          id: "ID",
          companyName: "社名",
          contactName: "担当者名",
          dks: "DKS-No頭文字",
          personInCharge: "管理会社担当者",
          telephoneNumber: "電話番号",
          comment: "コメント",
          status: "ステータス",
          updatedAt: "最終更新日",
          updatedBy: "最終更新者",
          form: "編集",
        },
        sortKey: {
          id: "ID",
          companyName: "社名",
          contactName: "担当者名",
          managementOffice: "管理会社",
        },
        comment: {
          exist: "有",
          notExist: "無し",
        },
        csvImportModalTitle: "準管理会社の入力",
      },
      form: {
        dksNoInitial: "DKS-No頭文字",
        companyName: "社名",
        companyNameJapan: "社名(カナ)",
        contactName: "担当者名",
        zipCode: "郵便番号",
        province: "都道府県",
        address1: "住所1",
        address2: "住所2",
        telephoneNumber: "電話番号",
        faxNumber: "ファックス",
        email: "メール",
        fileUpload: "アップロード",
        comment: "コメント",
        status: "ステータス",
        personInCharge: "管理会社担当者",
        fileUpLoadLabel: "画像をアップロード",
      },
    },
    vehicles: {
      csv: {
        importTitle: "車両の入力",
      }
    },
    spots: {
      csv: {
        importTitle: "地点の入力",
      },
      form: {
        telephoneNumber: "電話番号",
      },
      messages: {
        invalidTelephoneNumber: "電話番号は10桁から11桁の半角数字を入力してください。",
      }
    },
    selectRole: {
      selectRoleLabel: '組織を選択'
    },
    devices: {
      csv: {
        importTitle: "デバイスの入力",
        csvUpsertModalMessage: "ファイルを選択してください。「CSV一括更新出力」でダウンロードしたファイルを編集して、こちらからアップロードします。",
      }
    },
    roleAssignment: {
      title: "権限付与",
      form: {
        origanizations: "組織",
        roleName: "権限タイプ名",
        search: {
          title: "ユーザー検索",
          organization: "組織",
          userName: "ユーザー名",
          search: "検索",
        },
        header: {
          id: "ID",
          organization: "所属会社",
          userName: "ユーザー名",
          email: "email",
        },
        result: "検索結果",
        target: "対象ユーザー",
        all: "すべて選択",
      },
      modal: {
        notClearable: {
          row1: "権限が0件になるアカウントが含まれています。",
          row2: "アカウントに権限は必須です。",
        },
        duplicationConfirm: {
          row1: "同じ運営会社または組織で別の権限が付与されているアカウントが含まれています。",
          row2: "実行すると権限が上書きされます。",
        }
      },
      validations: {
        organization: {
          required: "組織を選択してください。",
        },
        roleType: {
          required: "権限タイプ名を選択してください。",
        },
        userSearch: {
          orzanization: {
            required: "組織を選択してください。",
          }
        }
      }
    },
    departments: {
      title: "組織一覧",
      list: {
        header: {
          id: "ID",
          operationOffice: "運営会社名",
          firstLevel: "第1階層",
          secondLevel: "第2階層",
          updatedAt: "最終更新日",
          updatedBy: "最終更新者",
          form: "編集",
          delete: "削除",
        }
      },
      form: {
        name: "組織名",
        parent: "所属元",
        status: "ステータス",
      },
      createOrUpdateModal: {
        createTitle: "組織作成",
        updateTitle: "組織編集",
        operationOffice: "運営会社",
        department: "第1階層",
        idForCustomer: "組織ID",
        name: "組織名",
        level: "階層",
        selectLevel: "階層を選択",
        firstLevel: "第1階層",
        secondLevel: "第2階層",
      },
      deleteModal: {
        title: "組織削除",
        deleteMessage: "を削除しますか？",
        informationMessage: "に所属している組織が存在するため削除できません。",
      },
    },
    user_roles: {
      title: {
        formTitle: "権限タイプ",
        listTitle: "権限タイプ一覧",
        createTitle: "作成",
        editTitle: "編集",
      },
      listHeader: {
        role_name: "権限タイプ名",
        officeable_type: "会社区分",
        office_name: "会社名",
        department_name: "組織名",
        updatedAt: "最終更新日",
        updatedBy: "最終更新者",
        form: "編集",
        delete: "削除",
      },
      search: {
        officeType: "会社区分",
        department: "組織",
        role: "権限タイプ名",
      },
      modal: {
        message: "権限タイプを削除します。よろしいですか？",
        submit_btn: "削除",
        cancel_btn: "キャンセル",
      },
      form: {
        name: "権限タイプ名",
        company_name: "会社名",
        department_name: "組織名",
        permission: "権限",
        template: "テンプレート",
        selectTemplate: "テンプレートから選択",
        action:{
          all:    "全て",
          read:   "閲覧",
          create: "作成",
          update: "更新",
          delete: "削除",
          output: "出力",
          input:  "入力",
        },
        button:{
          apply: "適用"
        },
        mask:{
          needSelect: "会社名または組織名を選択してください",
          loading: "読み込み中"
        },
        permissionItems:{
          daily_report:{
            suffix: "（特例適用）"
          }
        }
      },
      validate:{
        officeFormEmpty: "所属組織を入力してください",
        departmentFormEmpty: "組織を入力してください"
      }
    },
    downloadData: {
      messages: {
        operationOfficeRequired: "運営会社を選択してください。",
      }
    }
  },
  dashboard: {

  }
}
